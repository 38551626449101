import React from "react";

interface LegendProps {
  payload?: Array<{
    value: string;
    color: string;
    payload: {
      value: number;
      name: string;
    };
  }>;
}

export const CustomLegend: React.FC<LegendProps> = ({ payload }) => {
  if (!payload) return null;

  return (
    <ul className="list-none p-0 m-0">
      {payload.map((entry, index) => (
        <li key={`legend-item-${index}`} className="flex items-center mb-2">
          <span
            className="inline-block w-3 h-3 mr-2 rounded-sm"
            style={{ backgroundColor: entry.color }}
          />
          <span className="text-sm">
            {entry.value}:{" "}
            <strong>{entry.payload.value.toLocaleString()}</strong>
          </span>
        </li>
      ))}
    </ul>
  );
};
