/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from "react";
import { ToggleGroup } from "./ui/ToggleGroup";
import InstagramChannelMetrics from "../../../../models/entities/analytics/instagram-channel-metrics";
import { GeoCityDistributionChart } from "./GeoCityDistributionChart";
import { GeoCountryDistribution } from "./GeoCountryDistribution";

interface LocationsChartProps {
  analyticsData: InstagramChannelMetrics;
}

const METRICS = [
  { id: "reached", label: "Reach", color: "#FF6B6B" },
  { id: "engaged", label: "Engagement", color: "#4ECDC4" },
  { id: "followers", label: "Followers", color: "#45B7D1" },
] as const;

type MetricType = (typeof METRICS)[number]["id"];

export function LocationsChart({ analyticsData }: LocationsChartProps) {
  const [selectedMetric, setSelectedMetric] = useState<MetricType>("reached");

  // useEffect(() => {
  //   if (reachedData?.breakdown) {
  //     setSelectedMetric("reached");
  //   } else if (engagedData?.breakdown) {
  //     setSelectedMetric("engaged");
  //   } else if (followerData?.breakdown) {
  //     setSelectedMetric("followers");
  //   }
  // }, [reachedData?.breakdown, engagedData?.breakdown, followerData?.breakdown]);

  const countries = useMemo(
    () =>
      selectedMetric == "engaged"
        ? analyticsData?.demographics?.find(
            (x) =>
              x.metricName == "engaged_audience_demographics" &&
              x.breakdownType == "country"
          )
        : selectedMetric == "reached"
        ? analyticsData?.demographics?.find(
            (x) =>
              x.metricName == "reached_audience_demographics" &&
              x.breakdownType == "country"
          )
        : analyticsData?.followerDemographics?.find(
            (x) =>
              x.metricName == "follower_demographics" &&
              x.breakdownType == "country"
          ),
    [
      selectedMetric,
      analyticsData?.demographics,
      analyticsData?.followerDemographics,
    ]
  );

  const cities = useMemo(
    () =>
      selectedMetric == "engaged"
        ? analyticsData?.demographics?.find(
            (x) =>
              x.metricName == "engaged_audience_demographics" &&
              x.breakdownType == "city"
          )
        : selectedMetric == "reached"
        ? analyticsData?.demographics?.find(
            (x) =>
              x.metricName == "reached_audience_demographics" &&
              x.breakdownType == "city"
          )
        : analyticsData?.followerDemographics?.find(
            (x) =>
              x.metricName == "follower_demographics" &&
              x.breakdownType == "city"
          ),
    [
      selectedMetric,
      analyticsData?.demographics,
      analyticsData?.followerDemographics,
    ]
  );

  return (
    <div className="">
      <div className="flex items-center justify-between mb-2 px-6 py-4 bg-white rounded-xl shadow-sm ">
        <h2 className="text-lg font-semibold">Top Locations</h2>

        <ToggleGroup
          options={METRICS}
          value={selectedMetric}
          onChange={setSelectedMetric}
          shadow={false}
        />
      </div>
      <div className="h-[400px]">
        <div className="flex flex-row items-center gap-6">
          <div className="h-full flex-1">
            <GeoCountryDistribution fansByCountry={countries} />
          </div>
          <div className="h-full flex-1">
            <GeoCityDistributionChart fansByCity={cities} />
          </div>
        </div>
      </div>
    </div>
  );
}
