import dayjs from "dayjs";
import ChannelSlot from "../models/entities/channel-slot";
import daysOfWeek from "../data/days-of-week";
import Post from "../models/entities/post";
import ChannelSlotInstance from "../modules/scheduler/list/queue/models/channel-slot-instance";

export function generateInstantiatedSlots(
  startDay: dayjs.Dayjs,
  slots: ChannelSlot[],
  posts: Post[],
  timezone: string,
  dayRange: number = 30,
  sortDirection: "Ascending" | "Descending" = "Ascending"
): ChannelSlotInstance[] {
  const results: ChannelSlotInstance[] = [];
  const currentDateTimeTz = dayjs().tz(timezone);

  // Loop through the next {Range} days
  if (slots?.length > 0) {
    for (let i = 0; i < dayRange; i++) {
      const currentDate = startDay.add(i, "day");
      const currentDayOfWeek = daysOfWeek[currentDate.isoWeekday() - 1]; // Get the current day of the week

      // Filter the slots for the current day of the week
      const matchingSlots = slots.filter(
        (slot) => slot.day === currentDayOfWeek
      );

      // Create a new "instantiated" ChannelSlotInstance for each matching slot
      matchingSlots.forEach((slot) => {
        const slotDate = currentDate
          .set("hour", +slot.time.split(":")[0])
          .set("minute", +slot.time.split(":")[1]);

        if (slotDate.isBefore(currentDateTimeTz)) {
          return;
        }

        const instantiatedSlot: ChannelSlotInstance = {
          id: slot.id,
          day: currentDayOfWeek,
          time: slot.time,
          date: slotDate.format("YYYY-MM-DD"),
          dayjsDate: slotDate,
          post: undefined, // Initialize post as undefined
        };

        // Check if any post matches this slot's date and time
        const matchingPost = posts.find((post) => {
          if (!post.scheduledAt) return false;
          const postScheduledAt = dayjs(post.scheduledAt).tz(timezone);
          return postScheduledAt.isSame(slotDate);
        });

        if (matchingPost) {
          instantiatedSlot.post = matchingPost;
        }

        results.push(instantiatedSlot);
      });
    }
  }

  // Handle "one-off" posts that are not associated with any predefined slot
  const oneOffPosts = posts.filter((post) => {
    if (!post.scheduledAt) return false;
    const postScheduledAt = dayjs(post.scheduledAt).tz(timezone);

    const isWithinRangeDays =
      postScheduledAt.isAfter(startDay) &&
      postScheduledAt.isBefore(startDay.add(dayRange, "day"));

    const doesNotMatchAnySlot = !results.some(
      (slot) => slot.post?.id === post.id
    );
    return isWithinRangeDays && doesNotMatchAnySlot;
  });

  oneOffPosts.forEach((post) => {
    const postScheduledAt = dayjs(post.scheduledAt).tz(timezone);

    const oneOffSlot: ChannelSlotInstance = {
      id: `one-off-${post.id}`,
      day: daysOfWeek[postScheduledAt.isoWeekday()],
      time: postScheduledAt.format("HH:mm:ss"),
      date: postScheduledAt.format("YYYY-MM-DD"),
      dayjsDate: postScheduledAt,
      post: post,
    };

    results.push(oneOffSlot);
  });

  // Sort the final result by the slot's date and time in ascending order
  sortDirection === "Ascending"
    ? results.sort((a, b) => (a.dayjsDate.isBefore(b.dayjsDate) ? -1 : 1))
    : results.sort((a, b) => (b.dayjsDate.isBefore(a.dayjsDate) ? -1 : 1));

  return results;
}

export function generateInstantiatedSlotsOld(
  startDay: dayjs.Dayjs,
  slots: ChannelSlot[]
): ChannelSlotInstance[] {
  const results = [];

  // Loop through the next 30 days
  for (let i = 0; i < 100; i++) {
    const currentDate = startDay.add(i, "day"); // Add i days to startDate
    const currentDayOfWeek = daysOfWeek[currentDate.isoWeekday()]; // Get the day of the week

    // Filter the slots for thex current day of the week
    const matchingSlots = slots.filter((slot) => slot.day === currentDayOfWeek);

    // Create a new "instantiated" ChannelSlot for each matching slot
    matchingSlots.forEach((slot) => {
      const slotDate = dayjs(currentDate)
        .set("hour", +slot.time.split(":")[0])
        .set("minute", +slot.time.split(":")[1]);

      const instantiatedSlot = {
        id: slot.id,
        day: currentDayOfWeek,
        time: slot.time,
        date: slotDate.format("YYYY-MM-DD"), // Format the date as YYYY-MM-DD
        dayjsDate: slotDate, // Include the dayjs object for further manipulation if needed
      };
      results.push(instantiatedSlot);
    });
  }

  return results;
}
