import InstagramChannelMetrics from "../../models/entities/analytics/instagram-channel-metrics";
import ApiService from "../core/api-service";

class InstagramAnalyticsService {
  listAggregatedMetrics(
    channelId: string,
    timeframe: string
  ): Promise<InstagramChannelMetrics> {
    return ApiService.get<InstagramChannelMetrics>(
      `analytics/instagram/channels/${channelId}/aggregated`,
      {
        params: {
          timeframe,
        },
      }
    );
  }
}

export default new InstagramAnalyticsService();
