/* eslint-disable @typescript-eslint/no-explicit-any */

import clsx from "clsx";

interface ToggleOption {
  id: string;
  label: string;
  color: string;
}

interface ToggleGroupProps {
  options: readonly ToggleOption[];
  value: string;
  shadow?: boolean;
  onChange: (value: any) => void;
}

export function ToggleGroup({
  options,
  value,
  shadow = true,
  onChange,
}: ToggleGroupProps) {
  return (
    <div
      className={clsx(
        "inline-flex rounded-lg border border-gray-200 bg-white p-1",
        shadow && "shadow-sm"
      )}
    >
      {options.map((option) => (
        <button
          key={option.id}
          onClick={() => onChange(option.id)}
          className={clsx(
            `px-4 py-2 text-sm font-medium rounded-md transition-colors`,
            value === option.id
              ? "bg-gray-100 text-gray-900"
              : "text-gray-500 hover:text-gray-900 "
          )}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
