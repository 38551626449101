interface MetricItemProps {
  value: string | number;
  label: string;
}

export default function MetricItem({ value, label }: MetricItemProps) {
  return (
    <div className="flex items-center gap-2">
      <div>
        <div className="font-semibold text-gray-900">{value}</div>
        <div className="text-xs text-gray-500">{label}</div>
      </div>
    </div>
  );
}
