import { useMemo } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import timeframes, { Timeframe } from "../data/timeframes";

export interface TonePickerProps {
  onTimeframeChanged: (tone: Timeframe) => void;
  value?: Timeframe;
}

export default function TimeframePicker({
  value,
  onTimeframeChanged,
}: TonePickerProps) {
  const onChanged = (timeframe: Timeframe) => {
    onTimeframeChanged(timeframe);
  };

  const selected = useMemo(
    () => timeframes.find((x) => x.value === value?.value) ?? timeframes[2],
    [value]
  );

  return (
    <Listbox value={selected} onChange={onChanged}>
      <ListboxButton className="relative w-60 cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
        <span className="flex items-center">
          <span className="ml-3 block truncate">{selected?.label}</span>
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
          <ChevronUpDownIcon
            aria-hidden="true"
            className="h-5 w-5 text-gray-400"
          />
        </span>
      </ListboxButton>

      <ListboxOptions
        anchor="bottom"
        transition
        className="w-[var(--button-width)] [--anchor-gap:var(--spacing-1)] focus:outline-none transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
      >
        {timeframes.map((timeframe) => (
          <ListboxOption
            key={timeframe.value}
            value={timeframe}
            className="group relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary-600 data-[focus]:text-white"
          >
            <div className="flex items-center">
              <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                {timeframe.label}
              </span>
            </div>

            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
              <CheckIcon aria-hidden="true" className="h-5 w-5" />
            </span>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}
