import dayjs from "dayjs";
import Spinner from "../../../../components/common/Spinner";
import groupBy from "../../../../utils/list-utils";
import { generateInstantiatedSlots } from "../../../../utils/channel-slot-utils";
import { Fragment, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";
import queryNames from "../../../../queries/query-names";
import postQueueService from "../../../../services/api/post-queue-service";
import PostPreviewTile from "../../../scheduler/calendar/components/views/PostPreviewTile";
import PostActions from "../../../shared/post-actions/PostActions";
import ChannelSlotInstance from "../queue/models/channel-slot-instance";
import NoPosts from "../components/NoPosts";
import useCurrentChannel from "../../../../hooks/useCurrentChannel";
import TimezoneLabel from "../../../../components/common/TimezoneLabel";

export default function ListPublished() {
  const channel = useCurrentChannel();
  const socialSet = useCurrentSocialSet();
  const [slotsByDate, setSlotsByDate] = useState<
    Record<string, ChannelSlotInstance[]>
  >({});
  const postComposerRef = useRef(null);

  const { data: posts, isLoading } = useQuery({
    queryKey: [
      queryNames.postsByStatus,
      socialSet?.id,
      "Published",
      "Descending",
      channel?.id,
    ],
    queryFn: () =>
      postQueueService.listByStatus(
        socialSet.id,
        "Published",
        "Descending",
        channel?.id
      ),
  });

  useEffect(() => {
    const today = dayjs().tz(socialSet.timezone).subtract(1, "year");
    const startDay = today.startOf("day");

    const instantiatedSlots = generateInstantiatedSlots(
      startDay,
      [],
      posts ?? [],
      socialSet.timezone,
      1000,
      "Descending"
    );

    const slotsByDate = groupBy(instantiatedSlots, "date");
    setSlotsByDate(slotsByDate);
  }, [channel?.slots, socialSet.timezone, posts]);

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-md shadow overflow-y-auto bg-white h-full">
        <div className="lg:flex lg:flex-col">
          <div className="flex flex-col sm:flex-row gap-2 bg-white border-b sticky top-0 z-20 py-4 px-6">
            <div className="flex flex-col flex-1">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Published Posts
              </h2>
              <p className="mt-1 text-sm text-gray-500 leading-4 max-w-2xl">
                All posts that have been published to your social media
                channels.
              </p>
            </div>
            <TimezoneLabel />
          </div>

          <div className="px-8 py-6">
            <div className="flex flex-col gap-8 max-w-screen-md mx-auto">
              {isLoading && (
                <div className="mt-28">
                  <Spinner />
                </div>
              )}
              {!isLoading && (
                <>
                  {!Object.entries(slotsByDate).length && (
                    <NoPosts
                      title="No published posts"
                      description="You don't have any published posts yet. Once you publish some, they will show up here."
                    />
                  )}

                  {Object.entries(slotsByDate).map(([date, slots]) => (
                    <div key={date} className="flex flex-col gap-3">
                      <div className="flex gap-1 items-center justify-center text-md font-semibold">
                        {dayjs
                          .tz(date, socialSet.timezone)
                          .startOf("day")
                          .isSame(
                            dayjs().tz(socialSet.timezone).startOf("day")
                          ) && <span className="text-gray-800">Today,</span>}
                        {dayjs
                          .tz(date, socialSet.timezone)
                          .startOf("day")
                          .isSame(
                            dayjs()
                              .tz(socialSet.timezone)
                              .add(1, "day")
                              .startOf("day")
                          ) && (
                          <span className="text-gray-800">Tomorrow, </span>
                        )}
                        <span className="text-gray-800">
                          {dayjs.tz(date, socialSet.timezone).format("dddd")}
                        </span>
                        <span className="text-gray-500">
                          {dayjs.tz(date, socialSet?.timezone).year() !=
                          dayjs().tz(socialSet?.timezone).year()
                            ? dayjs
                                .tz(date, socialSet.timezone)
                                .format("DD MMMM YYYY")
                            : dayjs
                                .tz(date, socialSet.timezone)
                                .format("DD MMMM")}
                        </span>
                      </div>

                      <div className="flex flex-col gap-3">
                        <PostActions composerRef={postComposerRef}>
                          {({
                            deletePost,
                            isUpdating,
                            moveToDraft,
                            moveToScheduled,
                            publishNow,
                            editPost,
                            clonePost,
                          }) => (
                            <>
                              {slots.map((slot) => (
                                <Fragment key={slot.id}>
                                  {!slot.post && (
                                    <div className="rounded-md border p-2">
                                      <div className="text-center text-sm font-normal text-gray-800">
                                        {slot.dayjsDate.format("hh:mm A")}
                                      </div>
                                    </div>
                                  )}
                                  {slot.post && (
                                    <div className="rounded-md border">
                                      <PostPreviewTile
                                        event={{
                                          id: slot.post.id,
                                          name: slot.post.title,
                                          time: "time",
                                          datetime: "datetime",
                                          post: slot.post,
                                        }}
                                        flat={true}
                                        isSubmitting={isUpdating}
                                        onDelete={(event) =>
                                          deletePost(event.post)
                                        }
                                        onEdit={() => editPost(slot.post)}
                                        onClone={() => clonePost(slot.post)}
                                        publishNow={(event) =>
                                          publishNow(event.post)
                                        }
                                        moveToDraft={(event) =>
                                          moveToDraft(event.post)
                                        }
                                        moveToScheduled={(event) =>
                                          moveToScheduled(event.post)
                                        }
                                      />
                                    </div>
                                  )}
                                </Fragment>
                              ))}
                            </>
                          )}
                        </PostActions>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
