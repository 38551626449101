import FacebookChannelMetrics from "../../models/entities/analytics/facebook-channel-metrics";
import ApiService from "../core/api-service";

class FacebookAnalyticsService {
  listAggregatedMetrics(
    channelId: string,
    timeframe: string
  ): Promise<FacebookChannelMetrics> {
    return ApiService.get<FacebookChannelMetrics>(
      `analytics/facebook/channels/${channelId}/aggregated`,
      {
        params: {
          timeframe,
        },
      }
    );
  }
}

export default new FacebookAnalyticsService();
