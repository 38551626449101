import dayjs from "dayjs";
import { Column } from "../../../../hooks/table/table";
import Post from "../../../../models/entities/post";

export const columns: Column<Post>[] = [
  {
    label: "POST",
    selector: (post: Post) => dayjs(post.scheduledAt).format("YYYY-MM-DD"),
    className: "sticky left-0 z-20 bg-gray-50 border-r border-gray-200",
  },
  {
    label: "TYPE",
    selector: (post: Post) =>
      post.config?.instagram?.contentOptions?.systemPostType,
    className: "text-center",
  },
  {
    label: "IMPRESSIONS",
    selector: (post: Post) => post.metrics?.instagram?.impressions,
  },
  {
    label: "REACH",
    selector: (post: Post) => post.metrics?.instagram?.reach,
  },
  {
    label: "VIDEO PLAYS",
    selector: (post: Post) => post.metrics?.instagram?.plays,
  },
  {
    label: "WATCH TIME",
    selector: (post: Post) => post.metrics?.instagram?.avgWatchTimeMs,
  },
  {
    label: "PROFILE VISITS",
    selector: (post: Post) => post.metrics?.instagram?.profileVisits,
  },
  {
    label: "FOLLOWS",
    selector: (post: Post) => post.metrics?.instagram?.follows,
  },
  {
    label: "LIKES",
    selector: (post: Post) => post.metrics?.instagram?.likes,
  },
  {
    label: "COMMENTS",
    selector: (post: Post) => post.metrics?.instagram?.comments,
  },
  {
    label: "SHARES",
    selector: (post: Post) => post.metrics?.instagram?.shares,
  },
  {
    label: "SAVES",
    selector: (post: Post) => post.metrics?.instagram?.saved,
  },
  {
    label: "TOTAL INTERACTIONS",
    selector: (post: Post) => post.metrics?.instagram?.totalInteractions,
  },
];
