import dayjs from "dayjs";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import FacebookChannelMetrics from "../../../../models/entities/analytics/facebook-channel-metrics";
import NoData from "../../components/NoData";
import { formatMetricCount } from "../../../../utils/metric-utils";

const VideoViewTypeChart = ({
  analyticsData,
}: {
  analyticsData: FacebookChannelMetrics;
}) => {
  // Data transformation function
  const transformData = (analyticsData: FacebookChannelMetrics) => {
    if (!analyticsData) {
      return null;
    }

    const { stepBuckets } = analyticsData;

    if (!stepBuckets || !Array.isArray(stepBuckets)) {
      // Handle cases where stepBuckets is undefined or not an array
      return [];
    }

    // Assuming we have a starting date or we can generate dates
    // For this example, let's assume each bucket represents a day starting from today
    const startDate = dayjs(); // You can adjust this to your actual starting date

    const transformedData = stepBuckets.map((bucket, index) => {
      // Generate a date for each bucket
      const date = startDate.add(index, "day").format("YYYY-MM-DD");

      // Extract 'autoplau' and 'click' from the bucket
      const autoplays = bucket.videoViewsAutoplay || 0;
      const clicks = bucket.videoViewsClick || 0;

      return {
        date,
        autoplays,
        clicks,
      };
    });

    return transformedData;
  };

  const data = transformData(analyticsData);

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Video Views</h3>
      <div className="h-[300px]">
        {data && data.length > 0 ? (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient
                    id="clicksGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#22d3ee" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#22d3ee" stopOpacity={0.1} />
                  </linearGradient>
                  <linearGradient
                    id="autoplaysGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#f97316" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#f97316" stopOpacity={0.1} />
                  </linearGradient>
                </defs>

                <Legend />

                <CartesianGrid strokeDasharray="1 4" />

                <XAxis
                  hide={true}
                  dataKey="date"
                  className="text-xs font-medium text-gray-500"
                  tickFormatter={(value) => dayjs(value).format("MMM DD")}
                />
                <YAxis
                  tickFormatter={formatMetricCount}
                  tickLine={{ stroke: "#e5e7eb" }}
                  axisLine={{ stroke: "#e5e7eb" }}
                  stroke="#1f2937"
                  className="text-sm"
                  scale="sqrt"
                />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="autoplays"
                  stroke="#22d3ee"
                  fillOpacity={1}
                  stackId="1"
                  fill="url(#clicksGradient)"
                  name="Autoplays"
                />
                <Area
                  type="monotone"
                  dataKey="clicks"
                  stroke="#f97316"
                  fillOpacity={1}
                  stackId="1"
                  fill="url(#autoplaysGradient)"
                  name="Clicks"
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default VideoViewTypeChart;
