import { Tooltip } from "react-tooltip";
import useCurrentChannel from "../../hooks/useCurrentChannel";
import FacebookAnalytics from "./facebook/components/FacebookAnalytics";
import InstagramAnalytics from "./instagram/components/InstagramAnalytics";

export default function Analytics() {
  // const description = `Analytics is a new feature which we are actively working on and will be released in early December 2024. Stay tuned!`;
  const currentChannel = useCurrentChannel();

  let content = null;

  if (currentChannel == null) {
    content = (
      <div className="mt-28">
        <div className="text-center text-gray-500">
          Please select a channel from the left-side navigation to view
          analytics.
        </div>
      </div>
    );
  } else if (currentChannel.type == "Facebook") {
    content = <FacebookAnalytics />;
  } else if (currentChannel.type == "Instagram") {
    content = <InstagramAnalytics />;
  } else {
    content = "Channel analytics is not available for this channel type yet.";
  }

  return (
    <>
      <div>
        <div className="flex-1 flex items-center justify-center py-6 h-full">
          {/* <ComingSoon description={description} /> */}
          {content}
        </div>
      </div>

      <Tooltip
        id="metrics-tooltip-placeholder"
        className="viraly-tooltip metric-tooltip"
      />
    </>
  );
}
