import { useState } from "react";
import { SortConfig } from "./table";

export function useSort<T>() {
  const [sortConfig, setSortConfig] = useState<SortConfig<T> | null>(null);

  const sort = (items: T[]): T[] => {
    if (!sortConfig || !sortConfig.direction) {
      return items;
    }

    return [...items].sort((a, b) => {
      const aValue = sortConfig.selector(a);
      const bValue = sortConfig.selector(b);

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortConfig.direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }

      return 0;
    });
  };

  const handleSort = (selector: (item: T) => string | number) => {
    setSortConfig((current) => ({
      selector,
      direction:
        // current?.selector === selector
        //   ? current.direction === "asc"
        //     ? "desc"
        //     : current.direction === "desc"
        //     ? null
        //     : "asc"
        //   : "asc",
        current?.selector === selector
          ? current.direction === "asc"
            ? "desc"
            : "asc"
          : "desc",
    }));
  };

  return {
    sort,
    sortConfig,
    handleSort,
  };
}
