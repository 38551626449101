import { usePagination } from "./usePagination";
import { useSort } from "./useSort";

export const useTableData = <T>(items: T[]) => {
  const { sort, sortConfig, handleSort } = useSort<T>();
  const sortedItems = sort(items);

  const {
    currentPage,
    rowsPerPage,
    totalPages,
    handlePageChange,
    handleRowsPerPageChange,
    getPageItems,
  } = usePagination({
    totalItems: sortedItems.length,
    initialRowsPerPage: 10,
    initialPage: 1,
  });

  const currentData = getPageItems(sortedItems);

  return {
    currentData,
    currentPage,
    totalPages,
    rowsPerPage,
    sortConfig,
    handleSort,
    handlePageChange,
    handleRowsPerPageChange,
  };
};
