export interface Timeframe {
  label: string;
  value: string;
}

const timeframes: Timeframe[] = [
  { label: "Today", value: "Today" },
  { label: "Yesterday", value: "Yesterday" },
  { label: "Last 7 Days", value: "Last7Days" },
  { label: "Last 14 Days", value: "Last14Days" },
  { label: "Last 30 Days", value: "Last30Days" },
  { label: "Last 90 Days", value: "Last90Days" },
  { label: "Last 6 Months", value: "Last6Months" },
  { label: "Last 12 Months", value: "Last12Months" },
  { label: "This Week", value: "ThisWeek" },
  { label: "Last Week", value: "LastWeek" },
  { label: "This Month", value: "ThisMonth" },
  { label: "Last Month", value: "LastMonth" },
  { label: "This Quarter", value: "ThisQuarter" },
  { label: "Last Quarter", value: "LastQuarter" },
  { label: "This Year", value: "ThisYear" },
  { label: "Last Year", value: "LastYear" },
  { label: "Year To Date", value: "YearToDate" },
  { label: "Quarter To Date", value: "QuarterToDate" },
  { label: "Month To Date", value: "MonthToDate" },
];

export default timeframes;
