import React from "react";
import {
  BarChart,
  Bar,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";

interface MiniBarChartProps {
  data: number[];
  color: string;
  barSize?: number; // Optional: Allow customization of bar width
  formatFunction: (value: number) => string;
}

export const MiniBarChart: React.FC<MiniBarChartProps> = ({
  data,
  color,
  barSize = 8, // Default bar width
  formatFunction,
}) => {
  const chartData = data.map((value, index) => ({ value, index }));
  const maxValue = Math.max(...data, 0);

  return (
    <div className="h-[50px] w-full mt-2">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          barCategoryGap={0}
          barGap={0}
        >
          {/* Hidden X-Axis for cleaner look */}
          <XAxis hide dataKey="index" tick={false} axisLine={false} />
          {/* Hidden Y-Axis with proper domain */}
          <YAxis hide domain={[0, maxValue]} scale="pow" />
          <Tooltip
            content={<CustomTooltip formatFunction={formatFunction} />}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey="value"
            fill={color}
            radius={4}
            barSize={barSize}
            background={{
              fill: "#f7f7f8",
              radius: 4,
            }}
          >
            {chartData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={color}
                cursor="pointer"
                className="hover:opacity-75 transition-all"
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
