import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { RowsPerPageSelect } from "./RowsPerPageSelect";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 200, 500, 1000];

  return (
    <div className="flex items-center justify-between gap-4 px-4 py-3 bg-white border-gray-200 sm:px-6">
      <RowsPerPageSelect
        value={rowsPerPage}
        onChange={onRowsPerPageChange}
        options={rowsPerPageOptions}
      />

      <div className="flex items-center gap-4">
        <span className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </span>

        <div className="flex items-center gap-2">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-1 rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-1 rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};
