import { Column, SortConfig } from "../../hooks/table/table";
import { SortIcon } from "./SortIcon";

interface TableHeaderProps<T> {
  columns: Column<T>[];
  onSort: (selector: (item: T) => string | number) => void;
  sortConfig: SortConfig<T> | null;
}

export function TableHeader<T>({
  columns,
  onSort,
  sortConfig,
}: TableHeaderProps<T>) {
  return (
    <thead className="bg-gray-50">
      <tr>
        {columns.map(({ label, selector, className }) => (
          <th
            key={label}
            onClick={() => onSort(selector)}
            className={`px-4 py-3 text-xs font-bold text-left cursor-pointer transition-all text-gray-600 group hover:text-gray-700 hover:bg-gray-200 ${
              className || ""
            }`}
          >
            <button className="inline-flex items-center justify-between space-x-1 w-full">
              <span className="">{label}</span>
              <span className="text-gray-400 group-hover:text-gray-700">
                <SortIcon
                  direction={
                    sortConfig?.selector === selector
                      ? sortConfig.direction
                      : null
                  }
                />
              </span>
            </button>
          </th>
        ))}
      </tr>
    </thead>
  );
}
