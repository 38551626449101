/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from "recharts";
import { StepBucket } from "../../../../models/entities/analytics/facebook-channel-metrics";
import NoData from "../../components/NoData";
import { formatMetricCount } from "../../../../utils/metric-utils";

interface PostEngagementChartProps {
  data: StepBucket[];
}

export const PostEngagementChart: React.FC<PostEngagementChartProps> = ({
  data,
}) => {
  const chartData = useMemo(() => {
    if (!data) return [];

    return data.map((item, index) => ({
      name: `Step ${index + 1}`,
      ...item,
    }));
  }, [data]);

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Post Engagement</h3>
      <div className="h-[300px]">
        {chartData && chartData.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chartData}>
              <XAxis
                dataKey="name"
                tickLine={{ stroke: "#e5e7eb" }}
                axisLine={{ stroke: "#e5e7eb" }}
                className="text-xs"
                hide={true}
              />
              <YAxis
                tickFormatter={formatMetricCount}
                tickLine={{ stroke: "#e5e7eb" }}
                axisLine={{ stroke: "#e5e7eb" }}
                stroke="#1f2937"
                className="text-sm"
                scale="sqrt"
              />
              <Tooltip />

              <CartesianGrid strokeDasharray="1 4" />

              <Area
                name="Impressions"
                type="monotone"
                dataKey="postImpressions"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
              />

              <Area
                name="Reach"
                type="monotone"
                dataKey="postReach"
                stackId="2"
                stroke="#82ca9d"
                fill="#82ca9d"
              />

              <Area
                name="Engagement"
                type="monotone"
                dataKey="postEngagement"
                stackId="3"
                stroke="#ffc658"
                fill="#ffc658"
              />

              <Legend />

              {/* <Line
              name="Impressions"
              type="monotone"
              dataKey="postImpressions"
              stroke="#FF0000"
            />
            <Line
              name="Reach"
              type="monotone"
              dataKey="postReach"
              stroke="#FFFF00"
            />
            <Line
              name="Enagement"
              type="monotone"
              dataKey="postEngagement"
              stroke="#FF00FF"
            /> */}
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};
