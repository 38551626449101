const MediaProductTypeColors = {
  AD: "#FF8042",
  REEL: "#00C49F",
  POST: "#a78bfa",
  STORY: "#0ea5e9",
  IGTV: "#FFBB28",
  CAROUSEL_CONTAINER: "#dc2626",
  OTHERS: "#27272a",
};

export default MediaProductTypeColors;
