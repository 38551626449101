const LABELS = {
  FOLLOWER: "Followers",
  NON_FOLLOWER: "Non-Followers",
  IGTV: "IGTV",
  POST: "Posts",
  REEL: "Reels",
  STORY: "Stories",
  CAROUSEL_CONTAINER: "Carousels",
};

export default LABELS;
