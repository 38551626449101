export function roundDecimal(num: number, decimalPlaces = 0) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  num = Math.round((num + "e" + decimalPlaces) as any);
  return Number(num + "e" + -decimalPlaces);
}

/**
 * Calculate logarithmic values for a dataset.
 * @param data - Array of numbers to transform.
 * @param base - The base of the logarithm (default is 10).
 * @param offset - A small offset to handle zeros or negatives (default is 0.1).
 * @returns An array of logarithmic values.
 */
export function calculateLogarithmicValues(
  data: number[],
  base: number = 10,
  offset: number = 0.1
): number[] {
  if (base <= 0 || base === 1) {
    throw new Error(
      "Base of the logarithm must be greater than 0 and not equal to 1."
    );
  }

  return data.map((value) => {
    const adjustedValue = value + offset; // Adjust to avoid zero or negative issues
    if (adjustedValue <= 0) {
      throw new Error(`Value ${value} is invalid after offset adjustment.`);
    }
    return Math.log(adjustedValue) / Math.log(base); // Change of base formula
  });
}
