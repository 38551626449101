import React from "react";

interface RowsPerPageSelectProps {
  value: number;
  onChange: (value: number) => void;
  options: number[];
}

export const RowsPerPageSelect: React.FC<RowsPerPageSelectProps> = ({
  value,
  onChange,
  options,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <label htmlFor="rows-per-page" className="text-sm text-gray-700">
        Rows per page:
      </label>
      <select
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="block w-20 rounded-md border-gray-300 py-1 pl-3 pr-8 text-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
