import React from "react";
import { ArrowUp, ArrowDown } from "lucide-react";
import { SortDirection } from "../../hooks/table/table";

interface SortIconProps {
  direction: SortDirection;
}

export const SortIcon: React.FC<SortIconProps> = ({ direction }) => {
  if (!direction)
    return (
      <ArrowDown className="h-4 w-4 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto transition-all" />
    );
  if (direction === "asc") return <ArrowUp className="h-4 w-4" />;
  return <ArrowDown className="h-4 w-4" />;
};
