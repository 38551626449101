import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";

export interface FacebookMetricsProps {
  post: Post;
}

export default function FacebookMetrics({ post }: FacebookMetricsProps) {
  const metrics = post.metrics.facebook;

  return (
    <div className="flex gap-4 text-center">
      <MetricItem
        value={formatMetricCount(metrics.impressions)}
        label="Impressions"
      />
      <MetricItem
        value={formatMetricCount(metrics.totalClicks)}
        label="Clicks"
      />
      <MetricItem
        value={formatMetricCount(metrics.reactionsTotal)}
        label="Reactions"
      />
      <MetricItem
        value={formatMetricCount(metrics.comments)}
        label="Comments"
      />
      <MetricItem value={formatMetricCount(metrics.shares)} label="Shares" />
    </div>
  );
}
