import { Image, Video, Calendar } from "lucide-react";

interface IconTypeCellProps {
  postType: "Image" | "Video" | "Text";
}

export default function IconTypeCell({ postType }: IconTypeCellProps) {
  const getPostTypeIcon = () => {
    switch (postType) {
      case "Image":
        return <Image className="h-5 w-5 text-blue-500" />;

      case "Video":
        return <Video className="h-5 w-5 text-purple-500" />;

      case "Text":
        return <Calendar className="h-5 w-5 text-green-500" />;
    }
  };

  return (
    <div className="flex items-center justify-start">{getPostTypeIcon()}</div>
  );
}
