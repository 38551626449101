import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import InstagramChannelMetrics from "../../../../models/entities/analytics/instagram-channel-metrics";
import { CustomLegend } from "./CustomLegend";
import MediaProductTypeColors from "../../data/instagram-mappings";
import LABELS from "../data/productTypes";
import NoData from "../../components/NoData";

interface Props {
  data: InstagramChannelMetrics["mediaProductTypeAggregate"]["shares"];
}

export const SavesChart: React.FC<Props> = ({ data }) => {
  const chartData = Object.entries(data ?? {})
    // .filter((x) => ["POST", "REEL", "STORY"].includes(x[0]))
    .map(([key, value]) => ({
      name: key,
      value,
    }));

  return (
    <div className="w-full h-[300px] bg-white rounded-xl p-6 shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Saves Distribution</h3>
      <div className="flex h-[calc(100%-2rem)]">
        {chartData?.length ? (
          <>
            <ResponsiveContainer width="60%" height="100%">
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  minAngle={20}
                  outerRadius={80}
                  innerRadius={60}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        MediaProductTypeColors[entry.name] ??
                        MediaProductTypeColors.OTHERS
                      }
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <div className="w-[40%] flex items-center">
              <CustomLegend
                payload={chartData.map((entry) => ({
                  value: LABELS[entry.name] ?? entry.name,
                  color:
                    MediaProductTypeColors[entry.name] ??
                    MediaProductTypeColors.OTHERS,
                  payload: entry,
                }))}
              />
            </div>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};
