/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ToggleGroup } from "./ui/ToggleGroup";
import { InstagramChannelDemographics } from "../../../../models/entities/analytics/instagram-channel-metrics";
import NoData from "../../components/NoData";

// interface SimpleAgeGenderMetric {
//   ageGroup: string;
//   Female: number;
//   Male: number;
//   Other: number;
// }

interface AgeGenderChartProps {
  reachedData: InstagramChannelDemographics;
  engagedData: InstagramChannelDemographics;
  followerData: InstagramChannelDemographics;
  reachedGenderData: InstagramChannelDemographics;
  engagedGenderData: InstagramChannelDemographics;
  followerGenderData: InstagramChannelDemographics;
}

const METRICS = [
  { id: "reached", label: "Reach", color: "#FF6B6B" },
  { id: "engaged", label: "Engagement", color: "#4ECDC4" },
  { id: "followers", label: "Followers", color: "#45B7D1" },
] as const;

type MetricType = (typeof METRICS)[number]["id"];

const GENDER_COLORS = {
  Female: "#f28dbb",
  Male: "#0190f0",
  Other: "#ffbb28",
};

export function AgeGenderChart({
  reachedData,
  engagedData,
  followerData,
  reachedGenderData,
  engagedGenderData,
  followerGenderData,
}: AgeGenderChartProps) {
  const [selectedMetric, setSelectedMetric] = useState<MetricType>("reached");

  useEffect(() => {
    if (reachedData?.breakdown) {
      setSelectedMetric("reached");
    } else if (engagedData?.breakdown) {
      setSelectedMetric("engaged");
    } else if (followerData?.breakdown) {
      setSelectedMetric("followers");
    }
  }, [reachedData?.breakdown, engagedData?.breakdown, followerData?.breakdown]);

  const preparedData = useMemo(() => {
    if (
      (selectedMetric == "reached" && !reachedData?.breakdown) ||
      (selectedMetric == "engaged" && !engagedData?.breakdown) ||
      (selectedMetric == "followers" && !followerData?.breakdown)
    ) {
      return [];
    }

    const ageGroups =
      selectedMetric == "reached"
        ? Object.keys(reachedData.breakdown)
        : selectedMetric == "engaged"
        ? Object.keys(engagedData.breakdown)
        : Object.keys(followerData.breakdown);

    const currentData = {
      reached: { data: reachedData, genderData: reachedGenderData },
      engaged: { data: engagedData, genderData: engagedGenderData },
      followers: { data: followerData, genderData: followerGenderData },
    }[selectedMetric];

    const totalAudience = Object.values(
      currentData.genderData.breakdown
    ).reduce((a, b) => a + b, 0);

    const ageGroupTotals = ageGroups.reduce((acc, ageGroup) => {
      acc[ageGroup] = currentData.data.breakdown[ageGroup];
      return acc;
    }, {} as Record<string, number>);

    const result = ageGroups.map((ageGroup) => {
      const total = ageGroupTotals[ageGroup];
      const rawValues = {
        Female: Math.round(
          currentData.data.breakdown[ageGroup] *
            (currentData.genderData.breakdown.F / totalAudience)
        ),
        Male: Math.round(
          currentData.data.breakdown[ageGroup] *
            (currentData.genderData.breakdown.M / totalAudience)
        ),
        Other: Math.round(
          currentData.data.breakdown[ageGroup] *
            (currentData.genderData.breakdown.U / totalAudience)
        ),
      };

      return {
        ageGroup,
        Female: (rawValues.Female / total) * 100,
        Male: (rawValues.Male / total) * 100,
        Other: (rawValues.Other / total) * 100,
        _Female: rawValues.Female,
        _Male: rawValues.Male,
        _Other: rawValues.Other,
      };
    });

    result.sort((a, b) => a.ageGroup.localeCompare(b.ageGroup));
    return result;
  }, [
    engagedData,
    engagedGenderData,
    followerData,
    followerGenderData,
    reachedData,
    reachedGenderData,
    selectedMetric,
  ]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 rounded shadow-lg border border-gray-200">
          <p className="font-semibold mb-2">{label}</p>
          {Object.entries(GENDER_COLORS).map(([gender, color]) => {
            const percentage =
              payload.find((p: any) => p.dataKey === gender)?.value || 0;
            const actualValue = payload[0].payload[`_${gender}`];
            return (
              <div key={gender} className="mb-1">
                <p style={{ color }}>
                  {`${gender}: ${percentage.toFixed(1)}%`}
                </p>
                <p style={{ color }} className="text-sm opacity-75">
                  {`Total: ${new Intl.NumberFormat().format(actualValue)}`}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold">Age & Gender</h2>
        <ToggleGroup
          options={METRICS}
          value={selectedMetric}
          onChange={setSelectedMetric}
        />
      </div>
      <div className="h-[400px]">
        {(selectedMetric == "reached" &&
          reachedData?.breakdown &&
          reachedGenderData?.breakdown) ||
        (selectedMetric == "engaged" &&
          engagedData?.breakdown &&
          engagedGenderData?.breakdown) ||
        (selectedMetric == "followers" &&
          followerData?.breakdown &&
          followerGenderData?.breakdown) ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={preparedData}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="2 4" verticalPoints={[-100]} />
              <XAxis
                tickLine={{ stroke: "transparent" }}
                axisLine={{ stroke: "#e5e7eb" }}
                className="text-sm"
                dataKey="ageGroup"
              />
              <YAxis
                domain={[0, 100]}
                padding={{ bottom: 6 }}
                tickLine={{ stroke: "transparent" }}
                axisLine={{ stroke: "transparent" }}
                stroke="#1f2937"
                className="text-sm"
                tickFormatter={(value) => `${value}%`}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {Object.entries(GENDER_COLORS).map(([gender, color]) => (
                <Bar
                  key={gender}
                  dataKey={gender}
                  fill={color}
                  name={gender}
                  radius={5}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
