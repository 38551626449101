import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import FacebookChannelMetrics from "../../../../models/entities/analytics/facebook-channel-metrics";
import NoData from "../../components/NoData";

const FollowsVsUnfollowsBarChart = ({
  analyticsData,
}: {
  analyticsData: FacebookChannelMetrics;
}) => {
  // Data transformation inside the component
  const transformData = (analyticsData: FacebookChannelMetrics) => {
    if (!analyticsData) {
      return null;
    }

    const { stepBuckets } = analyticsData;

    if (!stepBuckets || !Array.isArray(stepBuckets)) {
      // Handle cases where stepBuckets is undefined or not an array
      return [];
    }

    const transformedData = stepBuckets.map((bucket, index) => {
      // Assign a label to each data point based on the index
      const label = `Period ${index + 1}`;

      // Extract 'follows' and 'unfollows' views from the bucket
      const follows = bucket.follows || 0;
      const unfollows = bucket.unfollows || 0;

      return {
        date: label, // Using the label as the date
        follows,
        unfollows,
      };
    });

    return transformedData;
  };

  const data = transformData(analyticsData);

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Follows vs Unfollows</h3>
      <div className="h-[300px]">
        {data && data.length > 0 ? (
          <>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis hide={true} />
                <YAxis hide={true} scale="sqrt" />

                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <Tooltip cursor={{ fill: "transparent" }} />
                <Legend />
                <Bar
                  name="Follows"
                  dataKey="follows"
                  fill="#82ca9d"
                  radius={4}
                  barSize={20}
                  background={{
                    fill: "#82ca9d11",
                    radius: 4,
                  }}
                />
                <Bar
                  name="Unfollows"
                  dataKey="unfollows"
                  fill="#ff6f61"
                  radius={4}
                  barSize={20}
                  background={{
                    fill: "#ff6f6111",
                    radius: 4,
                  }}
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default FollowsVsUnfollowsBarChart;
