import dayjs from "dayjs";
import { Column } from "../../../../hooks/table/table";
import Post from "../../../../models/entities/post";

export const columns: Column<Post>[] = [
  {
    label: "POST",
    selector: (post: Post) => dayjs(post.scheduledAt).format("YYYY-MM-DD"),
    className: "sticky left-0 z-20 bg-gray-50 border-r border-gray-200",
  },
  {
    label: "TYPE",
    selector: (post: Post) =>
      post.config?.facebook?.contentOptions?.systemPostType,
    className: "text-center",
  },
  {
    label: "IMPRESSIONS",
    selector: (post: Post) => post.metrics?.facebook?.impressions,
  },
  {
    label: "REACH",
    selector: (post: Post) => post.metrics?.facebook?.reach,
  },
  {
    label: "REACH RATE",
    selector: (post: Post) => post.metrics?.facebook?.reachRate,
  },
  {
    label: "ENG. RATE",
    selector: (post: Post) => post.metrics?.facebook?.engagementRate,
  },
  {
    label: "CLICKS",
    selector: (post: Post) => post.metrics?.facebook?.totalClicks,
  },
  {
    label: "REACTIONS",
    selector: (post: Post) => post.metrics?.facebook?.reactionsTotal,
  },
  {
    label: "COMMENTS",
    selector: (post: Post) => post.metrics?.facebook?.comments,
  },
  {
    label: "SHARES",
    selector: (post: Post) => post.metrics?.facebook?.shares,
  },
  {
    label: "VIDEO VIEWS",
    selector: (post: Post) => post.metrics?.facebook?.videoViewsOrganic,
  },
  {
    label: "VIDEO WATCH",
    selector: (post: Post) => post.metrics?.facebook?.videoAvgTimeWatched,
  },
];
