import { useState } from "react";

interface PaginationOptions {
  totalItems: number;
  initialRowsPerPage?: number;
  initialPage?: number;
}

export const usePagination = ({
  totalItems,
  initialRowsPerPage = 10,
  initialPage = 1,
}: PaginationOptions) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const totalPages = Math.ceil(totalItems / rowsPerPage);

  // Ensure current page is valid when rows per page changes
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const newTotalPages = Math.ceil(totalItems / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage((prev) => Math.min(prev, newTotalPages));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPageItems = <T>(items: T[]): T[] => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return items.slice(startIndex, startIndex + rowsPerPage);
  };

  return {
    currentPage,
    rowsPerPage,
    totalPages,
    handlePageChange,
    handleRowsPerPageChange,
    getPageItems,
  };
};
