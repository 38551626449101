/* eslint-disable @typescript-eslint/no-explicit-any */
import { Building2 } from "lucide-react";
import React, { useMemo } from "react";
import NoData from "../../components/NoData";

interface GeoCityDistributionProps {
  fansByCity: any; // LocationMetric[];
}

const COLORS = [
  "#b91c1c", // Red-700 (Tokyo, Japan)
  "#ea580c", // Orange-600 (Delhi, India)
  "#f59e0b", // Amber-500 (Shanghai, China)
  "#facc15", // Yellow-400 (São Paulo, Brazil)
  "#a3e635", // Lime-400 (Mumbai, India)
  "#4ade80", // Green-500 (Mexico City, Mexico)
  "#2dd4bf", // Teal-400 (Beijing, China)
  "#60a5fa", // Blue-500 (Osaka, Japan)
  "#818cf8", // Indigo-400 (Cairo, Egypt)
  "#a8a29e", // Gray-400 (Dhaka, Bangladesh)
];

export const GeoCityDistributionChart: React.FC<GeoCityDistributionProps> = ({
  fansByCity,
}) => {
  const cityData = useMemo(() => {
    if (!fansByCity) return [];

    const total = fansByCity.reduce((sum, item) => sum + item.metricValue, 0);

    // Apply logarithmic scaling to the metric values
    const adjustedCityData = fansByCity.map((city: any) => {
      const adjustedValue = city.metricValue + 0.1; // Add small offset to handle zeros
      const logValue = Math.sqrt(adjustedValue);
      return {
        ...city,
        adjustedValue,
        logValue,
      };
    });

    // Find min and max log values for normalization
    const minLogValue = Math.min(...adjustedCityData.map((c) => c.logValue));
    const maxLogValue = Math.max(...adjustedCityData.map((c) => c.logValue));
    const logRange = maxLogValue - minLogValue || 1; // Avoid division by zero

    const minWidth = 5; // Minimum width percentage for visibility

    return adjustedCityData
      .sort((a, b) => b.metricValue - a.metricValue)
      .slice(0, 10)
      .map((city, index) => {
        const value = city.metricValue;
        const percentage = (value / total) * 100;

        // Calculate normalized width using logarithmic values
        const normalizedWidth =
          minWidth +
          ((city.logValue - minLogValue) / logRange) * (100 - minWidth);

        return {
          name: city.cityName,
          value: city.metricValue,
          percentage: percentage.toFixed(1),
          width: normalizedWidth,
          color: COLORS[index % COLORS.length],
        };
      });
  }, [fansByCity]);

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm space-y-8">
      <div>
        <div className="flex items-center gap-2 mb-4">
          <Building2 className="w-5 h-5 text-purple-500" />
          <h3 className="text-lg font-semibold">Top Cities</h3>
        </div>
        <div className="h-[312px]">
          {cityData && cityData.length > 0 ? (
            <>
              <div className="space-y-2">
                {cityData.map((city) => (
                  <div
                    className="flex items-center gap-4 hover:opacity-75 cursor-pointer transition-all"
                    key={city.name}
                    data-tooltip-id="tooltip-placeholder"
                    data-tooltip-content={`${city.value.toLocaleString()} fans located in ${
                      city.name
                    }`}
                  >
                    <div className="flex justify-start items-center gap-2 text-sm leading-none text-gray-600 w-40 line-clamp-1">
                      <span className="text-lg leading-tight">{city.flag}</span>
                      <span className="line-clamp-1">{city.name}</span>
                    </div>
                    <div className="flex-1 bg-gray-200 rounded-full h-2">
                      <div
                        className="bg-purple-500 h-2 rounded-full w-full"
                        style={{
                          width: `${city.width}%`,
                          // backgroundColor: city.color,
                        }}
                      />
                    </div>
                    <div className="w-16">
                      <span className="w-full text-xs text-gray-800 font-medium text-end">
                        {city.percentage}%
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
};
