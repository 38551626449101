import dayjs from "dayjs";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import NoData from "../../components/NoData";
import InstagramChannelMetrics from "../../../../models/entities/analytics/instagram-channel-metrics";
import {
  formatMetricCount,
  formatMetricDate,
} from "../../../../utils/metric-utils";
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-100">
        <p className="font-semibold text-gray-800 mb-2">
          {formatMetricDate(label)}
        </p>
        <div className="space-y-1">
          <p className="text-[#4ECDC4] flex items-center">
            <span className="w-3 h-3 rounded-full bg-[#4ECDC4] mr-2" />
            Follows: {new Intl.NumberFormat().format(payload[0].value)}
          </p>
          <p className="text-[#FF6B6B] flex items-center">
            <span className="w-3 h-3 rounded-full bg-[#FF6B6B] mr-2" />
            Unfollows: {new Intl.NumberFormat().format(payload[1].value)}
          </p>
        </div>
      </div>
    );
  }
  return null;
};

const FollowsUnfollowsChart = ({
  analyticsData,
}: {
  analyticsData: InstagramChannelMetrics;
}) => {
  const [opacity, setOpacity] = useState({
    follows: 1,
    unfollows: 1,
  });

  // Data transformation function
  const transformData = (analyticsData: InstagramChannelMetrics) => {
    if (!analyticsData) {
      return null;
    }

    const dailySeries = analyticsData.followsUnfollows?.dailySeries;

    if (!dailySeries || !Array.isArray(dailySeries)) {
      // Handle cases where stepBuckets is undefined or not an array
      return [];
    }

    // Assuming we have a starting date or we can generate dates
    // For this example, let's assume each bucket represents a day starting from today
    const startDate = dayjs(); // You can adjust this to your actual starting date

    const transformedData = dailySeries.map((bucket, index) => {
      // Generate a date for each bucket
      const date = startDate.add(index, "day").format("YYYY-MM-DD");

      // Extract 'autoplau' and 'click' from the bucket
      const follows = bucket.follows || 0;
      const unfollows = bucket.unfollows || 0;

      return {
        date,
        follows,
        unfollows,
      };
    });

    return transformedData;
  };

  const handleMouseEnter = (metric: "follows" | "unfollows") => {
    setOpacity({
      follows: metric === "follows" ? 1 : 0.3,
      unfollows: metric === "unfollows" ? 1 : 0.3,
    });
  };

  const handleMouseLeave = () => {
    setOpacity({ follows: 1, unfollows: 1 });
  };

  const data = transformData(analyticsData);

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Follows vs Unfollows</h3>
      <div className="h-[300px]">
        {data && data.length > 0 ? (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient
                    id="followsGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#4ECDC4" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#4ECDC4" stopOpacity={0.1} />
                  </linearGradient>
                  <linearGradient
                    id="unfollowsGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#FF6B6B" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#FF6B6B" stopOpacity={0.1} />
                  </linearGradient>
                </defs>

                <Legend
                  iconType="plainline"
                  onMouseEnter={(dataKey) =>
                    handleMouseEnter(dataKey.dataKey as "follows" | "unfollows")
                  }
                  onMouseLeave={handleMouseLeave}
                />

                <CartesianGrid strokeDasharray="1 4" />
                <XAxis
                  dataKey="date"
                  tickLine={{ stroke: "#e5e7eb" }}
                  axisLine={{ stroke: "#e5e7eb" }}
                  className="text-xs"
                  tickFormatter={formatMetricDate}
                />
                <YAxis
                  tickFormatter={formatMetricCount}
                  tickLine={{ stroke: "#e5e7eb" }}
                  axisLine={{ stroke: "#e5e7eb" }}
                  stroke="#1f2937"
                  className="text-sm"
                  scale="sqrt"
                />
                <Tooltip content={<CustomTooltip />} />

                <Area
                  type="monotone"
                  dataKey="follows"
                  stroke="#4ECDC4"
                  fillOpacity={opacity.follows}
                  stackId="1"
                  fill="url(#followsGradient)"
                  name="Follows"
                />
                <Area
                  type="monotone"
                  dataKey="unfollows"
                  stroke="#FF6B6B"
                  fillOpacity={opacity.unfollows}
                  stackId="2"
                  fill="url(#unfollowsGradient)"
                  name="Unfollows"
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default FollowsUnfollowsChart;
