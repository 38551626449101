import React from "react";
import { TableHeader } from "../../../../components/table/TableHeader";
import { Pagination } from "../../../../components/table/Pagination";
import { useTableData } from "../../../../hooks/table/useTableData";
import Post from "../../../../models/entities/post";

import {
  formatMetricCount,
  formatMetricDuration,
} from "../../../../utils/metric-utils";
import { columns } from "../data/tableColumns";
import ContentCell from "./ContentCell";
import IconTypeCell from "./IconTypeCell";

export interface PostsTableProps {
  posts: Post[];
}

export default function PostsTabel({ posts }: PostsTableProps) {
  const {
    currentData,
    currentPage,
    totalPages,
    rowsPerPage,
    sortConfig,
    handleSort,
    handlePageChange,
    handleRowsPerPageChange,
  } = useTableData(posts);

  const getPostType = (post: Post): "Image" | "Video" | "Text" => {
    switch (post.config.instagram.contentOptions.systemPostType) {
      case "PhotoStory":
      case "ImagePost":
        return "Image";
      case "Reel":
      case "VideoStory":
        return "Video";
      case "Carousel":
        return "Text"; // TODO: Change to carousel
    }
  };

  return (
    <div className="flex flex-col">
      <div className="relative rounded-lg border overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <TableHeader
              columns={columns}
              onSort={handleSort}
              sortConfig={sortConfig}
            />
            <tbody className="divide-y divide-gray-200 bg-white">
              {currentData.map((post, index) => {
                const postType = getPostType(post);

                return (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 group cursor-pointer"
                  >
                    <td className="w-96 sticky left-0 z-20 bg-white px-4 py-2 text-sm text-gray-800 border-r border-gray-200 group-hover:bg-gray-50">
                      <ContentCell post={post} />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
                      <IconTypeCell postType={postType} />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {post.config.instagram.contentOptions.postType != "Post"
                        ? "-"
                        : formatMetricCount(
                            post.metrics?.instagram?.impressions
                          )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(post.metrics?.instagram?.reach)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {post.config.instagram.contentOptions.postType != "Reel"
                        ? "-"
                        : formatMetricCount(post.metrics?.instagram?.plays)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {post.config.instagram.contentOptions.postType != "Reel"
                        ? "-"
                        : formatMetricDuration(
                            post.metrics?.instagram?.avgWatchTimeMs
                          )}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(
                        post.metrics?.instagram?.profileVisits
                      )}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(post.metrics?.instagram?.follows)}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(post.metrics?.instagram?.likes)}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(post.metrics?.instagram?.comments)}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(post.metrics?.instagram?.shares)}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(post.metrics?.instagram?.saved)}
                    </td>

                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-800">
                      {formatMetricCount(
                        post.metrics?.instagram?.totalInteractions
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );
}
