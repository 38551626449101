/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { InstagramTimeSeries } from "../../../../models/entities/analytics/instagram-channel-metrics";
import {
  formatMetricCount,
  formatMetricDate,
} from "../../../../utils/metric-utils";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-100">
        <p className="font-semibold text-gray-800 mb-2">
          {formatMetricDate(label)}
        </p>
        <div className="space-y-1">
          <p className="text-[#f97316] flex items-center">
            <span className="w-3 h-3 rounded-full bg-[#f97316] mr-2" />
            Reach: {new Intl.NumberFormat().format(payload[0].value)}
          </p>
          <p className="text-[#22d3ee] flex items-center">
            <span className="w-3 h-3 rounded-full bg-[#22d3ee] mr-2" />
            Engaged: {new Intl.NumberFormat().format(payload[1].value)}
          </p>
        </div>
      </div>
    );
  }
  return null;
};

interface MetricsChartProps {
  data: InstagramTimeSeries[];
}

export function ReachEngagementChart({ data }: MetricsChartProps) {
  const [opacity, setOpacity] = useState({
    reach: 1,
    engagement: 1,
  });

  const handleMouseEnter = (metric: "reach" | "accounts_engaged") => {
    setOpacity({
      reach: metric === "reach" ? 1 : 0.3,
      engagement: metric === "accounts_engaged" ? 1 : 0.3,
    });
  };

  const handleMouseLeave = () => {
    setOpacity({ reach: 1, engagement: 1 });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center gap-3 mb-6">
        <h2 className="text-lg font-semibold mb-4">
          Reach & Engagement Trends
        </h2>
      </div>
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="reachGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f97316" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#f97316" stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id="engagementGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="5%" stopColor="#22d3ee" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#22d3ee" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="1 4" />
            <XAxis
              dataKey="date"
              tickFormatter={formatMetricDate}
              tickLine={{ stroke: "#e5e7eb" }}
              axisLine={{ stroke: "#e5e7eb" }}
              className="text-xs"
            />
            <YAxis
              yAxisId="reach"
              tickFormatter={formatMetricCount}
              scale="sqrt"
              tickLine={{ stroke: "#e5e7eb" }}
              axisLine={{ stroke: "#e5e7eb" }}
              stroke="#1f2937"
              className="text-sm"
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              iconType="plainline"
              onMouseEnter={(dataKey) =>
                handleMouseEnter(
                  dataKey.dataKey as "reach" | "accounts_engaged"
                )
              }
              onMouseLeave={handleMouseLeave}
            />
            <Area
              type="monotone"
              dataKey="reach"
              stroke="#f97316"
              fillOpacity={opacity.reach}
              fill="url(#reachGradient)"
              strokeWidth={2}
              yAxisId="reach"
              name="Reach"
            />
            <Area
              type="monotone"
              dataKey="accounts_engaged"
              stroke="#22d3ee"
              fillOpacity={opacity.engagement}
              fill="url(#engagementGradient)"
              strokeWidth={2}
              yAxisId="reach"
              name="Engagement"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
