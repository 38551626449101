import { roundDecimal } from "./math-utils";

export function formatMetricCount(num: number) {
  if (isNaN(num)) {
    return "0";
  }

  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
}

export function formatMetricPercentage(num: number) {
  return isNaN(num) ? "0%" : roundDecimal(num, 2) + "%";
}

export function formatMetricDuration(num: number): string {
  const minutes = num / 60;
  const hours = num / 3600;
  const days = num / (3600 * 24);

  if (hours < 1) {
    return `${minutes.toFixed(1)}m`;
  } else if (hours >= 1 && hours < 24 * 7) {
    return `${hours.toFixed(1)}h`;
  } else {
    const fullDays = Math.floor(days);
    const remainingHours = ((days - fullDays) * 24).toFixed(1);
    return `${fullDays}d ${remainingHours}h`;
  }
}

export function formatMetricDate(dateString: string): string {
  return new Date(dateString).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
}
