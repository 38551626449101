import React from "react";
import { MiniBarChart } from "./MiniBarChart";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import {
  formatMetricCount,
  formatMetricDuration,
  formatMetricPercentage,
} from "../../../utils/metric-utils";
import clsx from "clsx";

interface MetricCardProps {
  title: string;
  value: number;
  previousValue: number;
  chartData: number[];
  color: string;
  tooltip?: string;
  format?: "count" | "percentage" | "duration";
  positiveTrend?: "up" | "down";
}

export const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  previousValue,
  chartData,
  color,
  tooltip,
  format = "count",
  positiveTrend = "up",
}) => {
  const trend = previousValue
    ? ((value - previousValue) / previousValue) * 100
    : 0;

  const formatFunction =
    format == "count"
      ? formatMetricCount
      : format == "percentage"
      ? formatMetricPercentage
      : format == "duration"
      ? formatMetricDuration
      : (val) => val;

  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-start justify-between">
        <div>
          <p className="text-sm font-semibold text-gray-600">{title}</p>
          <div
            className="flex items-center gap-2"
            data-tooltip-id="metrics-tooltip-placeholder"
            data-tooltip-content={`Current period: ${value?.toLocaleString()}\nPrevious period: ${previousValue?.toLocaleString()}`}
          >
            <h3 className="text-2xl font-bold mt-1">{formatFunction(value)}</h3>

            {trend > 0.1 && (
              <span
                className={clsx(
                  "text-sm whitespace-nowrap",
                  trend > 0 &&
                    (positiveTrend == "up" ? "text-green-500" : "text-red-500"),
                  trend < 0 &&
                    (positiveTrend == "down"
                      ? "text-green-500"
                      : "text-red-500")
                )}
              >
                {trend >= 0 ? "↑" : "↓"} {formatMetricPercentage(trend)}
              </span>
            )}
          </div>
        </div>
        <div>
          <InformationCircleIcon
            data-tooltip-id="metrics-tooltip-placeholder"
            data-tooltip-content={tooltip}
            className="w-5 h-5 text-gray-400"
          />
        </div>
      </div>

      <div className="mt-4">
        <MiniBarChart
          data={chartData}
          color={color}
          formatFunction={formatFunction}
        />
      </div>
    </div>
  );
};
