import React from "react";
import Spinner from "./Spinner";

// Define the props interface
interface WidgetPlaceholderProps {
  loading: boolean;
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
}

// Define the ContentPlaceholder component
const WidgetPlaceholder: React.FC<WidgetPlaceholderProps> = ({
  loading,
  children,
  loadingComponent,
}) => {
  // Render the loading component or default spinner if loading is true
  if (loading) {
    return <>{loadingComponent || <Spinner size="5" />}</>;
  }

  // Render children when loading is false
  return <>{children}</>;
};

export default WidgetPlaceholder;
