import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { format } from "timeago.js";
import Spinner from "../../../../components/common/Spinner";
import { findChannelConfig } from "../../../../data/channels-config";
import useCurrentChannel from "../../../../hooks/useCurrentChannel";
import queryNames from "../../../../queries/query-names";
import postService from "../../../../services/api/post-service";
import analyticsService from "../../../../services/api/analytics-service";
import { refetchQuery } from "../../../../queries/query-utils";
import Button from "../../../../components/common/Button";
import alertService from "../../../../services/alert/alert-service";
import PostsTable from "./PostsTable";
import instagramAnalyticsService from "../../../../services/api/instagram-analytics-service";
import WidgetPlaceholder from "../../../../components/common/WidgetPlaceholder";
import MetricSkeleton from "../../../../components/skeletons/MetricSkeleton";
import { MetricCard } from "../../components/MetricCard";
import { DailyMetrics } from "../../../../models/entities/analytics/instagram-channel-metrics";
import timeframes, { Timeframe } from "../../data/timeframes";
import TimeframePicker from "../../components/TimeframePicker";
import { LikesChart } from "./LikesChart";
import { CommentsChart } from "./CommentsChart";
import { SharesChart } from "./SharesChart";
import { SavesChart } from "./SavesChart";
import { AgeGenderChart } from "./AgeGenderChart";
import { ReachMetrics } from "./ReachMetrics";
import FollowsUnfollowsChart from "./FollowsUnfollowsChart";
import ChartSkeleton from "../../../../components/skeletons/ChartSkeleton";
import { LocationsChart } from "./LocationsChart";
import { ReachEngagementChart } from "./ReachAndEngagementChart";

export default function InstagramAnalytics() {
  const [timeframe, setTimeframe] = useState<Timeframe>(timeframes[2]);
  const [syncChannelId, setSyncChannelId] = useState<string>(null);
  const [requestingSync, isRequestingSync] = useState<boolean>(false);

  const currentChannel = useCurrentChannel();

  const lastRefresh = useMemo(
    () =>
      currentChannel.metricsFetchedAt == null
        ? "never"
        : format(currentChannel.metricsFetchedAt, "en_US"),
    [currentChannel.metricsFetchedAt]
  );

  const startDate = useMemo(
    () => dayjs().subtract(365, "days").startOf("day").format("YYYY-MM-DD"),
    []
  );
  const endDate = useMemo(() => dayjs().endOf("day").format("YYYY-MM-DD"), []);

  const { isLoading, data: posts = [] } = useQuery({
    queryKey: [
      queryNames.postsByDateRange,
      currentChannel?.id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      postService.list(
        currentChannel.socialSetId,
        startDate,
        endDate,
        0,
        currentChannel.id,
        "Published"
      ),
  });

  const analyticsQueryKey = useMemo(
    () => ["channel-analytics", currentChannel.id, timeframe],
    [currentChannel.id, timeframe]
  );

  const {
    isLoading: isLoadingAnalytics,
    data: analyticsData = {
      simpleDaily: {
        current: {},
        previous: {},
        buckets: [],
      },
      mediaProductTypeAggregate: {
        likes: [],
        comments: [],
        shares: [],
        saves: [],
      },
      uniqueAccountsEngagedComparison: {
        currentPeriod: [],
        previousPeriod: [],
      },
      followsUnfollows: {
        current: {},
        previous: {},
        buckets: [],
        dailySeries: [],
      },
      reachAndEngagedTimeSeries: [],
      uniqueReach: [],
      demographics: [],
      followerDemographics: [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
  } = useQuery({
    queryKey: analyticsQueryKey,
    queryFn: () =>
      instagramAnalyticsService.listAggregatedMetrics(
        currentChannel.id,
        timeframe.value
      ),
  });

  const isSyncing = currentChannel.syncStartedAt != null || requestingSync;

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    const process = async () => {
      if (isSyncing) {
        console.log("Syncing in progress...");
        setSyncChannelId(currentChannel.id);
        // Poll every 5 seconds while syncing
        interval = setInterval(() => {
          refetchQuery([queryNames.socialSets]);
        }, 5000);
      } else {
        if (syncChannelId !== currentChannel.id) return;

        setSyncChannelId(currentChannel.id);
        console.log("Syncing completed.");
        await refetchQuery(analyticsQueryKey);
        alertService.info("Insights have been successfully synced.");
      }
    };

    process();

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isSyncing]);

  const onTimeframeChanged = (range) => {
    setTimeframe(range);
  };

  const getMetricData = (metric: keyof DailyMetrics) => {
    return analyticsData.simpleDaily.buckets.map((bucket) => bucket[metric]);
  };

  const syncAnalytics = async () => {
    try {
      isRequestingSync(true);
      await analyticsService.syncChannel(currentChannel.id);
      await refetchQuery([queryNames.socialSets]);
    } finally {
      isRequestingSync(false);
    }
  };

  const demographics = analyticsData.demographics ?? [];
  const followerDemographics = analyticsData.followerDemographics ?? [];

  const findMetric = (
    data: typeof demographics,
    metricName: string,
    breakdownType: string
  ) =>
    data.find(
      (d) => d.metricName == metricName && d.breakdownType === breakdownType
    )!;

  return (
    <>
      <div className="min-h-screen w-full">
        <div className="mx-auto space-y-8">
          <div className="flex items-center justify-between bg-white rounded-lg shadow py-4 px-6 gap-4">
            <div className="flex items-center space-x-4 py-4 truncate">
              <div className="relative">
                <img
                  referrerPolicy="no-referrer"
                  className="h-12 w-12 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                  src={
                    currentChannel.pictureUrl ??
                    findChannelConfig(currentChannel.type).imageUrl
                  }
                />
                <img
                  className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-8 right-0 -mr-2"
                  src={findChannelConfig(currentChannel.type).imageUrl}
                />
              </div>

              <h2 className="flex-1 text-xl font-semibold truncate text-gray-900">
                {currentChannel.name}
              </h2>
            </div>

            <div className="flex items-center space-x-2">
              <Button
                text="Export Data"
                color="gray"
                variant="outline"
                icon={<ArrowDownTrayIcon className="w-4 mr-2" />}
                onClick={() => alertService.info("Not implemented yet.")}
              />

              <Button
                text="Sync Insights"
                color="gray"
                variant="outline"
                icon={<ArrowPathIcon className="w-4 mr-2" />}
                onClick={syncAnalytics}
                disabled={isSyncing}
                data-tooltip-id="tooltip-placeholder"
                loading={isSyncing}
                loadingText="Syncing in progress..."
                data-tooltip-html={
                  isSyncing
                    ? "Insights are currently being synced. Please check back in a few moments."
                    : `<div class="text-center">Insights are automatically synced every 24 hours.\nLast synced: ${lastRefresh}</div>`
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-end">
            <TimeframePicker
              onTimeframeChanged={onTimeframeChanged}
              value={timeframe}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6 mb-8">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Impressions"
                value={analyticsData.simpleDaily.current.impressions}
                previousValue={analyticsData.simpleDaily.previous.impressions}
                chartData={getMetricData("impressions")}
                color="#3b82f6"
                tooltip="The number of times your posts, stories, reels, videos and live videos were on screen, including in ads."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Total Interactions"
                value={analyticsData.simpleDaily.current.total_interactions}
                previousValue={
                  analyticsData.simpleDaily.previous.total_interactions
                }
                chartData={getMetricData("total_interactions")}
                color="#3b82f6"
                tooltip="The total number of post interactions, story interactions, reels interactions, video interactions and live video interactions, including any interactions on boosted content."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Likes"
                value={analyticsData.simpleDaily.current.likes}
                previousValue={analyticsData.simpleDaily.previous.likes}
                chartData={getMetricData("likes")}
                color="#3b82f6"
                tooltip="The number of likes on your posts, reels, and videos."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Comments"
                value={analyticsData.simpleDaily.current.comments}
                previousValue={analyticsData.simpleDaily.previous.comments}
                chartData={getMetricData("comments")}
                color="#3b82f6"
                tooltip="The number of comments on your posts, reels, videos and live videos."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Shares"
                value={analyticsData.simpleDaily.current.shares}
                previousValue={analyticsData.simpleDaily.previous.shares}
                chartData={getMetricData("shares")}
                color="#3b82f6"
                tooltip="The number of shares of your posts, stories, reels, videos and live videos."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Saves"
                value={analyticsData.simpleDaily.current.saves}
                previousValue={analyticsData.simpleDaily.previous.saves}
                chartData={getMetricData("saves")}
                color="#3b82f6"
                tooltip="The number of saves of your posts, reels, and videos."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Replies"
                value={analyticsData.simpleDaily.current.replies}
                previousValue={analyticsData.simpleDaily.previous.replies}
                chartData={getMetricData("replies")}
                color="#3b82f6"
                tooltip="The number of replies you received from your story, including text replies and quick reaction replies."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Follows"
                value={analyticsData.followsUnfollows.current.follows}
                previousValue={analyticsData.followsUnfollows.previous.follows}
                chartData={analyticsData.followsUnfollows.buckets.map(
                  (x) => x.follows
                )}
                color="#3b82f6"
                tooltip="The number of accounts that followed you."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Unfollows"
                value={analyticsData.followsUnfollows.current.unfollows}
                previousValue={
                  analyticsData.followsUnfollows.previous.unfollows
                }
                chartData={analyticsData.followsUnfollows.buckets.map(
                  (x) => x.unfollows
                )}
                color="#3b82f6"
                tooltip="The number of accounts that unfollowed you or left Instagram in the selected time period."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Accounts Engaged"
                value={
                  analyticsData.uniqueAccountsEngagedComparison
                    ?.currentPeriod?.[0]?.value
                }
                previousValue={
                  analyticsData.uniqueAccountsEngagedComparison
                    ?.previousPeriod?.[0]?.value
                }
                chartData={getMetricData("impressions").map(() => 0)}
                color="#3b82f6"
                tooltip="The number of accounts that have interacted with your content, including in ads"
              />
            </WidgetPlaceholder>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <LikesChart
                data={analyticsData.mediaProductTypeAggregate.likes}
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <CommentsChart
                data={analyticsData.mediaProductTypeAggregate.comments}
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <SharesChart
                data={analyticsData.mediaProductTypeAggregate.shares}
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <SavesChart
                data={analyticsData.mediaProductTypeAggregate.saves}
              />
            </WidgetPlaceholder>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <AgeGenderChart
                reachedData={findMetric(
                  demographics,
                  "reached_audience_demographics",
                  "age"
                )}
                engagedData={findMetric(
                  demographics,
                  "engaged_audience_demographics",
                  "age"
                )}
                followerData={findMetric(
                  followerDemographics,
                  "follower_demographics",
                  "age"
                )}
                reachedGenderData={findMetric(
                  demographics,
                  "reached_audience_demographics",
                  "gender"
                )}
                engagedGenderData={findMetric(
                  demographics,
                  "engaged_audience_demographics",
                  "gender"
                )}
                followerGenderData={findMetric(
                  followerDemographics,
                  "follower_demographics",
                  "gender"
                )}
              />
            </WidgetPlaceholder>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <ReachMetrics uniqueReach={analyticsData.uniqueReach} />
            </WidgetPlaceholder>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <ReachEngagementChart
                data={analyticsData.reachAndEngagedTimeSeries}
              />
            </WidgetPlaceholder>
          </div>
          <div className="grid grid-cols-1 gap-6">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <FollowsUnfollowsChart analyticsData={analyticsData} />
            </WidgetPlaceholder>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<ChartSkeleton />}
            >
              <LocationsChart analyticsData={analyticsData} />
            </WidgetPlaceholder>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6 mt-6">
            <div className="">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Post Performance ({posts.length} posts)
              </h2>
            </div>
            {isLoading && (
              <div>
                <Spinner size="5" />
              </div>
            )}
            {!isLoading && <PostsTable posts={posts} />}
          </div>
        </div>
      </div>
    </>
  );
}
