import Post from "../../../../../models/entities/post";
import FacebookMetrics from "./FacebookMetrics";
import InstagramMetrics from "./InstagramMetrics";

export interface MetricsProps {
  post: Post;
}

export default function Metrics({ post }: MetricsProps) {
  let content = null;
  if (post.config.channelType == "Facebook" && post.metrics?.facebook) {
    content = <FacebookMetrics post={post} />;
  }
  if (post.config.channelType == "Instagram" && post.metrics?.instagram) {
    content = <InstagramMetrics post={post} />;
  }

  return content == null ? null : <div className="px-4 pb-2">{content}</div>;
}
