import dayjs from "dayjs";
import Post from "../../../../models/entities/post";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";

interface ContentCellProps {
  post: Post;
}

export default function ContentCell({ post }: ContentCellProps) {
  const currentSocialSet = useCurrentSocialSet();

  return (
    <>
      <div className="min-w-72 max-w-96 flex gap-2 justify-between items-center">
        <div className="flex flex-col justify-center gap-1 line-clamp-2 h-[55px]">
          <div className="line-clamp-2 text-sm leading-tight">
            {post.title?.substring(0, 120)}
          </div>
          <div className="text-xs text-gray-500">
            {dayjs(post.scheduledAt)
              .tz(currentSocialSet.timezone)
              .format("MMM DD, YYYY, hh:mm A")}
          </div>
        </div>

        {post.postAttachments.length > 0 ? (
          <img
            src={post.postAttachments[0].attachment.thumbnails["small"].url}
            alt=""
            className="h-10 w-10 rounded-lg object-cover"
          />
        ) : null}
      </div>
    </>
  );
}
