import { useState } from "react";
import ChannelConfig from "../models/channel-config";
import Button from "../../../../components/common/Button";
import AccountPickerItem from "../models/account-picker-item";
import {
  ArrowsRightLeftIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";
import HelpLinksDropdown from "../../../channels/components/help-links-dropdown/HelpLinksDropdown";

export interface AccountPickerDialogProps {
  onConfirm: (accounts: AccountPickerItem[]) => void;
  onCancel?: () => void;
  channelConfig: ChannelConfig;
  accounts: AccountPickerItem[];
}

export default function AccountPickerDialog({
  onConfirm,
  onCancel,
  channelConfig,
  accounts,
}: AccountPickerDialogProps) {
  const [selected, setSelected] = useState<AccountPickerItem[]>([]);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(selected);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const toggle = (account: AccountPickerItem) => {
    if (!account.available) {
      return;
    }

    const cloned = [...selected];
    const index = cloned.findIndex((x) => x.id == account.id);

    index !== -1 ? cloned.splice(index, 1) : cloned.push(account);

    setSelected(cloned);
  };

  return (
    <>
      <div className="fixed inset-0 flex flex-col w-screen items-center justify-center p-4 z-50">
        <div className="flex justify-center gap-6">
          <img
            src="/logo/viraly-logo-square-dense.png"
            className="h-12 w-auto"
          />
          <ArrowsRightLeftIcon className="w-5" />
          <img src={channelConfig.imageUrl} />
        </div>

        <div className="relative transform rounded-lg bg-white p-2 sm:p-4 text-left shadow-xl transition-all my-8 sm:w-full sm:max-w-lg">
          <div className="sm:flex sm:flex-1 sm:items-start flex">
            <div className="mt-3 text-center mx-2 sm:mt-0 sm:text-left flex-1">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Which channels would you like to connect?
              </h3>
              <div className="mt-4 mb-2">
                {accounts.length == 0 ? (
                  <div className="divide-y divide-gray-100">
                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <div className="mt-6">
                        <div className="text-md text-gray-500 mr-2">
                          No accounts found
                        </div>
                        {/* <a href="#" className="text-sm font-semibold leading-6 text-primary-600">
                          Learn more <span>→</span>
                        </a> */}
                      </div>
                    </dd>
                  </div>
                ) : (
                  <ul
                    role="list"
                    className="divide-y divide-gray-100 overflow-auto max-h-[50vh] bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md"
                  >
                    {accounts.map((account) => (
                      <li
                        key={account.id}
                        className={clsx(
                          "text-left relative flex justify-between gap-x-6 px-4 py-3 sm:px-6",
                          account.available
                            ? "cursor-pointer hover:bg-gray-50"
                            : "cursor-default bg-gray-50 opacity-60"
                        )}
                        onClick={() => toggle(account)}
                      >
                        <div className="flex min-w-0 gap-x-4">
                          <div className="relative">
                            <img
                              referrerPolicy="no-referrer"
                              className="h-9 w-9 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                              src={account.picture ?? channelConfig.imageUrl}
                            />
                            <img
                              className="h-5 w-5 flex-none rounded-full border border-white border-3 bg-gray-50 absolute top-6 right-0 -mr-2"
                              src={channelConfig.imageUrl}
                            />
                          </div>

                          <div className="min-w-0 flex-1">
                            <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                              <span>{account.name}</span>
                            </p>
                            <p className="flex text-xs text-gray-500">
                              <span className="relative truncate">
                                {account.subtitle}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex shrink-0 items-center gap-x-4">
                          {!account.available && (
                            <div className="flex gap-2">
                              <span className="text-xs font-semibold text-gray-900">
                                Unavailable
                              </span>
                              <InformationCircleIcon
                                className="h-4 w-4 text-gray-400"
                                data-tooltip-id="account-picker-tooltip"
                                data-tooltip-content="The channel is already connected to Viraly"
                              />
                            </div>
                          )}
                          {account.available && (
                            <input
                              readOnly={true}
                              type="checkbox"
                              checked={
                                selected.findIndex((x) => x.id == account.id) !=
                                -1
                              }
                              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-transparent"
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-end gap-2 mx-2">
            <div className="mr-auto">
              <HelpLinksDropdown channelType={channelConfig?.type} />
            </div>

            <Button
              variant="outline"
              color="slate"
              text="Cancel"
              onClick={handleCancel}
            />

            <Button
              variant="solid"
              color="brand"
              text="Start Connecting"
              onClick={handleConfirm}
              disabled={!selected.length}
            />
          </div>
        </div>
      </div>

      <Tooltip id="account-picker-tooltip" className="viraly-tooltip" />
    </>
  );
}
