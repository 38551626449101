import CreatePostRequest from "../../models/api-requests/create-post-request";
import Post, { PostStatus } from "../../models/entities/post";
import ApiService from "../core/api-service";

class PostsService {
  list(
    socialSetId: string,
    startDate: string,
    endDate: string,
    timezoneOffset: number,
    channelId?: string,
    status?: PostStatus
  ): Promise<Post[]> {
    return ApiService.get<Post[]>(`posts`, {
      params: {
        socialSetId,
        startDate,
        endDate,
        timezoneOffset,
        channelId,
        status,
      },
      disableGlobalErrorReporting: true,
    });
  }

  get(id: string): Promise<Post> {
    return ApiService.get<Post>(`posts/${id}`);
  }

  // Comma-separated list of ids
  getMultiple(socialSetId: string, ids: string): Promise<Post[]> {
    return ApiService.get<Post[]>(
      `posts/multiple?ids=${ids}&socialSetId=${socialSetId}`
    );
  }

  create(model: CreatePostRequest): Promise<Post> {
    return ApiService.post<Post>(`posts`, model);
  }

  update(id: string, model: CreatePostRequest): Promise<Post> {
    return ApiService.put<Post>(`posts/${id}`, model);
  }

  delete(id: string): Promise<void> {
    return ApiService.delete<void>(`posts/${id}`);
  }
}

export default new PostsService();
