/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import { Globe2 } from "lucide-react";
import countries from "../../data/countries";
import NoData from "../../components/NoData";
import { InstagramChannelDemographics } from "../../../../models/entities/analytics/instagram-channel-metrics";

interface GeoCountryDistributionProps {
  fansByCountry: InstagramChannelDemographics; // Replace 'any' with the appropriate type if available
}

const COLORS = [
  "#b91c1c", // Red-700 (China)
  "#ea580c", // Orange-600 (India)
  "#f59e0b", // Amber-500 (United States)
  "#facc15", // Yellow-400 (Indonesia)
  "#a3e635", // Lime-400 (Pakistan)
  "#86efac", // Green-300 (Nigeria)
  "#5eead4", // Teal-300 (Brazil)
  "#93c5fd", // Blue-200 (Bangladesh)
  "#c7d2fe", // Indigo-200 (Russia)
  "#f3f4f6", // Gray-100 (Mexico)
];

export const GeoCountryDistribution: React.FC<GeoCountryDistributionProps> = ({
  fansByCountry,
}) => {
  const countryData = useMemo(() => {
    if (!fansByCountry) return [];

    const total = Object.entries(fansByCountry.breakdown).reduce(
      (sum, item) => sum + item[1],
      0
    );

    // Apply logarithmic scaling to the metric values
    const adjustedCountryData = Object.entries(fansByCountry.breakdown).map(
      (country) => {
        const adjustedValue = country[1] + 0.1; // Add small offset to handle zeros
        const logValue = Math.sqrt(adjustedValue);
        return {
          countryCode: country[0],
          value: country[1],
          adjustedValue,
          logValue,
        };
      }
    );

    // Find min and max log values for normalization
    const minLogValue = Math.min(...adjustedCountryData.map((c) => c.logValue));
    const maxLogValue = Math.max(...adjustedCountryData.map((c) => c.logValue));
    const logRange = maxLogValue - minLogValue || 1; // Avoid division by zero

    const minWidth = 5; // Minimum width percentage for visibility

    return adjustedCountryData
      .sort((a, b) => b.value - a.value)
      .slice(0, 10)
      .map((country, index) => {
        const value = country.value;
        const percentage = (value / total) * 100;

        // Calculate normalized width using logarithmic values
        const normalizedWidth =
          minWidth +
          ((country.logValue - minLogValue) / logRange) * (100 - minWidth);

        return {
          code: country.countryCode,
          name:
            countries[country.countryCode]?.name ?? countries["DEFAULT"].name,
          flag:
            countries[country.countryCode]?.flag ?? countries["DEFAULT"].flag,
          value: value,
          percentage: percentage.toFixed(1),
          width: normalizedWidth,
          color: COLORS[index % COLORS.length],
        };
      });
  }, [fansByCountry]);

  return (
    <div className="bg-white p-6 rounded-xl shadow-sm space-y-8">
      <div>
        <div className="flex items-center gap-2 mb-4">
          <Globe2 className="w-5 h-5 text-red-500" />
          <h3 className="text-lg font-semibold">Top Countries</h3>
        </div>
        <div className="h-[312px]">
          {countryData && countryData.length > 0 ? (
            <div className="space-y-2">
              {countryData.map((country) => (
                <div
                  className="flex items-center gap-4 hover:opacity-75 cursor-pointer transition-all"
                  key={country.name}
                  data-tooltip-id="tooltip-placeholder"
                  data-tooltip-content={`${country.value.toLocaleString()} fans located in ${
                    country.name
                  }`}
                >
                  <div className="flex justify-start items-center gap-2 text-sm leading-none text-gray-600 w-40 line-clamp-1">
                    <span className="text-lg leading-tight">
                      {country.flag}
                    </span>
                    <span className="line-clamp-1">{country.name}</span>
                  </div>
                  <div className="flex-1 bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-red-500 h-2 rounded-full w-full transition-all duration-1000"
                      style={{
                        width: `${country.width}%`,
                        // backgroundColor: country.color,
                      }}
                    />
                  </div>
                  <div className="w-16">
                    <span className="w-full text-xs text-gray-800 font-medium text-end">
                      {country.percentage}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
};
