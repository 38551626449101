/* eslint-disable @typescript-eslint/no-explicit-any */
import { PureComponent, useMemo } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import MediaProductTypeColors from "../../data/instagram-mappings";
import NoData from "../../components/NoData";

interface ReachData {
  metricName: string;
  period: string;
  value: number;
  breakdownType: string;
  breakdown: Record<string, number>;
  endTime: string;
}

interface ReachMetricsProps {
  uniqueReach: ReachData[];
}

const COLORS = {
  followerType: ["#ec4899", "#818cf8"],
  mediaType: ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4", "#FFEEAD"],
  combinedType: [
    "#FF6B6B",
    "#4ECDC4",
    "#45B7D1",
    "#96CEB4",
    "#D4A5A5",
    "#9FA8DA",
    "#80DEEA",
    "#A5D6A7",
    "#FFE082",
    "#FFAB91",
  ],
};

const LABELS = {
  FOLLOWER: "Followers",
  NON_FOLLOWER: "Non-Followers",
  IGTV: "IGTV",
  POST: "Posts",
  REEL: "Reels",
  STORY: "Stories",
  CAROUSEL_CONTAINER: "Carousels",
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={fill}
        className="text-sm font-medium"
      >
        {LABELS[payload.name as keyof typeof LABELS] || payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        className="text-sm"
      >
        {new Intl.NumberFormat().format(value)}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        className="text-xs"
      >
        {`(${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

class ReachPieChart extends PureComponent<{
  data: { name: string; value: number }[];
  colorSet: string[];
  title: string;
}> {
  state = {
    activeIndex: 0,
  };

  onPieEnter = (_: any, index: number) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { data, colorSet, title } = this.props;

    return (
      <div>
        <div className="h-[300px]">
          {data && data.length ? (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  activeIndex={this.state.activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  minAngle={15}
                  onMouseEnter={this.onPieEnter}
                >
                  {data.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colorSet[index % colorSet.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <NoData />
          )}
        </div>
        <h3 className="text-sm text-gray-800 font-semibold text-center">
          {title}
        </h3>
      </div>
    );
  }
}

class ProductTypeReachPieChart extends PureComponent<{
  data: { name: string; value: number }[];
  title: string;
}> {
  state = {
    activeIndex: 0,
  };

  onPieEnter = (_: any, index: number) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { data, title } = this.props;

    return (
      <div>
        <div className="h-[300px]">
          {data && data.length ? (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  activeIndex={this.state.activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  minAngle={15}
                  onMouseEnter={this.onPieEnter}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        MediaProductTypeColors[entry.name] ??
                        MediaProductTypeColors.OTHERS
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <NoData />
          )}
        </div>
        <h3 className="text-sm text-gray-800 font-semibold text-center">
          {title}
        </h3>
      </div>
    );
  }
}

export function ReachMetrics({ uniqueReach = [] }: ReachMetricsProps) {
  const followerData = uniqueReach.find(
    (d) => d.breakdownType === "follow_type"
  )!;
  const mediaData = uniqueReach.find(
    (d) => d.breakdownType === "media_product_type"
  )!;
  //   const combinedData = uniqueReach.find(
  //     (d) => d.breakdownType === "media_product_type,follow_type"
  //   )!;

  const formatFollowerData = useMemo(
    () =>
      followerData?.breakdown
        ? Object.entries(followerData.breakdown).map(([name, value]) => ({
            name,
            value,
          }))
        : [],
    [followerData?.breakdown]
  );

  const formatMediaData = useMemo(
    () =>
      mediaData?.breakdown
        ? Object.entries(mediaData.breakdown).map(([name, value]) => ({
            name,
            value,
          }))
        : [],
    [mediaData?.breakdown]
  );

  //   const formatCombinedData = () =>
  //     Object.entries(combinedData.breakdown).map(([name, value]) => ({
  //       name,
  //       value,
  //     }));

  return (
    <div className="w-full bg-white rounded-xl p-6 shadow-sm">
      <h2 className="text-lg font-semibold mb-4">Reach Distribution</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ReachPieChart
          data={formatFollowerData}
          colorSet={COLORS.followerType}
          title="By Follow Status"
        />
        <ProductTypeReachPieChart
          data={formatMediaData}
          title="By Media Type"
        />
        {/* <ReachPieChart
          data={formatCombinedData()}
          colorSet={COLORS.combinedType}
          title="Combined Distribution"
        /> */}
      </div>
    </div>
  );
}
