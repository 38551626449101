import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { format } from "timeago.js";
import Spinner from "../../../../components/common/Spinner";
import { findChannelConfig } from "../../../../data/channels-config";
import useCurrentChannel from "../../../../hooks/useCurrentChannel";
import queryNames from "../../../../queries/query-names";
import postService from "../../../../services/api/post-service";
import PostsTableV3 from "./PostsTable";
import facebookAnalyticsService from "../../../../services/api/facebook-analytics-service";
import { StepBucket } from "../../../../models/entities/analytics/facebook-channel-metrics";
import { MetricCard } from "../../components/MetricCard";
import analyticsService from "../../../../services/api/analytics-service";
import { refetchQuery } from "../../../../queries/query-utils";
import Button from "../../../../components/common/Button";
import alertService from "../../../../services/alert/alert-service";
import WidgetPlaceholder from "../../../../components/common/WidgetPlaceholder";
import MetricSkeleton from "../../../../components/skeletons/MetricSkeleton";
import { GeoCityDistributionChart } from "./GeoCityDistributionChart";
import { PostEngagementChart } from "./PostEngagementChart";
import VideoViewTypeChart from "./VideoViewTypeChart";
import FollowsVsUnfollowsBarChart from "./FollowsVsUnfollowsChart";
import ChartSkeleton from "../../../../components/skeletons/ChartSkeleton";
import { GeoCountryDistribution } from "./GeoCountryDistribution";
import timeframes, { Timeframe } from "../../data/timeframes";
import TimeframePicker from "../../components/TimeframePicker";

export default function FacebookAnalytics() {
  const [timeframe, setTimeframe] = useState<Timeframe>(timeframes[2]);
  const [syncChannelId, setSyncChannelId] = useState<string>(null);
  const [requestingSync, isRequestingSync] = useState<boolean>(false);

  const currentChannel = useCurrentChannel();

  const lastRefresh = useMemo(
    () =>
      currentChannel.metricsFetchedAt == null
        ? "never"
        : format(currentChannel.metricsFetchedAt, "en_US"),
    [currentChannel.metricsFetchedAt]
  );

  const startDate = useMemo(
    () => dayjs().subtract(365, "days").startOf("day").format("YYYY-MM-DD"),
    []
  );
  const endDate = useMemo(() => dayjs().endOf("day").format("YYYY-MM-DD"), []);

  const { isLoading, data: posts = [] } = useQuery({
    queryKey: [
      queryNames.postsByDateRange,
      currentChannel?.id,
      startDate,
      endDate,
    ],
    queryFn: () =>
      postService.list(
        currentChannel.socialSetId,
        startDate,
        endDate,
        0,
        currentChannel.id,
        "Published"
      ),
  });

  const analyticsQueryKey = useMemo(
    () => ["channel-analytics", currentChannel.id, timeframe],
    [currentChannel.id, timeframe]
  );

  const { isLoading: isLoadingAnalytics, data: analyticsData } = useQuery({
    queryKey: analyticsQueryKey,
    queryFn: () =>
      facebookAnalyticsService.listAggregatedMetrics(
        currentChannel.id,
        timeframe.value
      ),
  });

  const isSyncing = currentChannel.syncStartedAt != null || requestingSync;

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    const process = async () => {
      if (isSyncing) {
        console.log("Syncing in progress...");
        setSyncChannelId(currentChannel.id);
        // Poll every 5 seconds while syncing
        interval = setInterval(() => {
          refetchQuery([queryNames.socialSets]);
        }, 5000);
      } else {
        if (syncChannelId !== currentChannel.id) return;

        setSyncChannelId(currentChannel.id);
        console.log("Syncing completed.");
        await refetchQuery(analyticsQueryKey);
        alertService.info("Insights have been successfully synced.");
      }
    };

    process();

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isSyncing]);

  const onTimeframeChanged = (range) => {
    setTimeframe(range);
  };

  const getMetricData = (metric: keyof StepBucket) => {
    return analyticsData?.stepBuckets.map((bucket) => bucket[metric]);
  };

  const syncAnalytics = async () => {
    try {
      isRequestingSync(true);
      await analyticsService.syncChannel(currentChannel.id);
      await refetchQuery([queryNames.socialSets]);
    } finally {
      isRequestingSync(false);
    }
  };

  return (
    <>
      <div className="min-h-screen w-full">
        <div className="mx-auto space-y-8">
          <div className="flex items-center justify-between bg-white rounded-lg shadow py-4 px-6 gap-4">
            <div className="flex items-center space-x-4 py-4 truncate">
              <div className="relative">
                <img
                  referrerPolicy="no-referrer"
                  className="h-12 w-12 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                  src={
                    currentChannel.pictureUrl ??
                    findChannelConfig(currentChannel.type).imageUrl
                  }
                />
                <img
                  className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-8 right-0 -mr-2"
                  src={findChannelConfig(currentChannel.type).imageUrl}
                />
              </div>

              <h2 className="flex-1 text-xl font-semibold truncate text-gray-900">
                {currentChannel.name}
              </h2>
            </div>

            <div className="flex items-center space-x-2">
              <Button
                text="Export Data"
                color="gray"
                variant="outline"
                icon={<ArrowDownTrayIcon className="w-4 mr-2" />}
                onClick={() => alertService.info("Not implemented yet.")}
              />

              <Button
                text="Sync Insights"
                color="gray"
                variant="outline"
                icon={<ArrowPathIcon className="w-4 mr-2" />}
                onClick={syncAnalytics}
                disabled={isSyncing}
                data-tooltip-id="tooltip-placeholder"
                loading={isSyncing}
                loadingText="Syncing in progress..."
                data-tooltip-html={
                  isSyncing
                    ? "Insights are currently being synced. Please check back in a few moments."
                    : `<div class="text-center">Insights are automatically synced every 24 hours.\nLast synced: ${lastRefresh}</div>`
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-end">
            <TimeframePicker
              onTimeframeChanged={onTimeframeChanged}
              value={timeframe}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6 mb-8">
            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Followers"
                value={analyticsData?.current.followers}
                previousValue={analyticsData?.previous.followers}
                chartData={getMetricData("followers")}
                color="#3b82f6"
                tooltip="The number of followers of your Facebook Page."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Page Follows"
                value={analyticsData?.current.follows}
                previousValue={analyticsData?.previous.follows}
                chartData={getMetricData("follows")}
                color="#3b82f6"
                tooltip="The number of times people have engaged with your posts through reactions, comments, shares and more."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Page Unfollows"
                value={analyticsData?.current.unfollows}
                previousValue={analyticsData?.previous.unfollows}
                chartData={getMetricData("unfollows")}
                color="#3b82f6"
                tooltip="The number of times people have engaged with your posts through reactions, comments, shares and more."
                positiveTrend="down"
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Impressions"
                value={analyticsData?.current.impressions}
                previousValue={analyticsData?.previous.impressions}
                chartData={getMetricData("impressions")}
                color="#3b82f6"
                tooltip="The number of times any content from your Page or about your Page entered a person's screen."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Reach"
                value={analyticsData?.current.reach}
                previousValue={analyticsData?.previous.reach}
                chartData={getMetricData("reach")}
                color="#3b82f6"
                tooltip="The number of people who had any content from your Page or about your Page enter their screen."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Video Views"
                value={analyticsData?.current.videoViews}
                previousValue={analyticsData?.previous.videoViews}
                chartData={getMetricData("videoViews")}
                color="#3b82f6"
                tooltip="The number of times your Page's videos were viewed for 3 seconds or more."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Video Clicks"
                value={analyticsData?.current.videoViewsClick}
                previousValue={analyticsData?.previous.videoViewsClick}
                chartData={getMetricData("videoViewsClick")}
                color="#3b82f6"
                tooltip="The number of times your Page's videos were viewed for 3 seconds or more."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Video Autoplays"
                value={analyticsData?.current.videoViewsAutoplay}
                previousValue={analyticsData?.previous.videoViewsAutoplay}
                chartData={getMetricData("videoViewsAutoplay")}
                color="#3b82f6"
                tooltip="The number of times your Page's videos were viewed for 3 seconds or more."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Video Watch Time"
                value={analyticsData?.current.videoViewTime}
                previousValue={analyticsData?.previous.videoViewTime}
                chartData={getMetricData("videoViewTime")}
                color="#3b82f6"
                tooltip="The total time people viewed your Page's videos."
                format="duration"
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Video Reach"
                value={analyticsData?.current.videoViewsReach}
                previousValue={analyticsData?.previous.videoViewsReach}
                chartData={getMetricData("videoViewsReach")}
                color="#3b82f6"
                tooltip="The number of people who viewed your Page's videos for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Post Impressions"
                value={analyticsData?.current.postImpressions}
                previousValue={analyticsData?.previous.postImpressions}
                chartData={getMetricData("postImpressions")}
                color="#3b82f6"
                tooltip="The number of times your Page's posts entered a person's screen. Posts include statuses, photos, links, videos and more."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Post Reach"
                value={analyticsData?.current.postReach}
                previousValue={analyticsData?.previous.postReach}
                chartData={getMetricData("postReach")}
                color="#3b82f6"
                tooltip="The number of people who had any of your Page's posts enter their screen."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Post Engagement"
                value={analyticsData?.current.postEngagement}
                previousValue={analyticsData?.previous.postEngagement}
                chartData={getMetricData("postEngagement")}
                color="#3b82f6"
                tooltip="The number of times people have engaged with your posts through reactions, comments, shares and more."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Engagement Rate"
                value={analyticsData?.current.engagementRate}
                previousValue={analyticsData?.previous.engagementRate}
                chartData={getMetricData("engagementRate")}
                color="#3b82f6"
                format="percentage"
                tooltip="The engagement rate is determined by taking the number of post engagements, dividing it by the post reach, and then multiplying the result by 100."
              />
            </WidgetPlaceholder>

            <WidgetPlaceholder
              loading={isLoadingAnalytics}
              loadingComponent={<MetricSkeleton />}
            >
              <MetricCard
                title="Reach Rate"
                value={analyticsData?.current.reachRate}
                previousValue={analyticsData?.previous.reachRate}
                chartData={getMetricData("reachRate")}
                color="#3b82f6"
                format="percentage"
                tooltip="The reach rate is determined by taking the number of account followers, dividing it by the post reach, and then multiplying the result by 100."
              />
            </WidgetPlaceholder>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
            <div className="lg:col-span-6">
              <WidgetPlaceholder
                loading={isLoadingAnalytics}
                loadingComponent={<ChartSkeleton />}
              >
                <PostEngagementChart data={analyticsData?.stepBuckets} />
              </WidgetPlaceholder>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-6 gap-6">
            <div className="lg:col-span-3">
              <WidgetPlaceholder
                loading={isLoadingAnalytics}
                loadingComponent={<ChartSkeleton />}
              >
                <VideoViewTypeChart analyticsData={analyticsData} />
              </WidgetPlaceholder>
            </div>

            <div className="lg:col-span-3">
              <WidgetPlaceholder
                loading={isLoadingAnalytics}
                loadingComponent={<ChartSkeleton />}
              >
                <FollowsVsUnfollowsBarChart analyticsData={analyticsData} />
              </WidgetPlaceholder>
            </div>
          </div>

          <div className="flex flex-row items-center gap-6">
            <div className="h-full flex-1">
              <WidgetPlaceholder
                loading={isLoadingAnalytics}
                loadingComponent={<ChartSkeleton />}
              >
                <GeoCountryDistribution
                  fansByCountry={analyticsData?.current.fansByCountry}
                />
              </WidgetPlaceholder>
            </div>
            <div className="h-full flex-1">
              <WidgetPlaceholder
                loading={isLoadingAnalytics}
                loadingComponent={<ChartSkeleton />}
              >
                <GeoCityDistributionChart
                  fansByCity={analyticsData?.current.fansByCity}
                />
              </WidgetPlaceholder>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Post Performance ({posts.length} posts)
              </h2>
            </div>
            {isLoading && (
              <div>
                <Spinner size="5" />
              </div>
            )}
            {!isLoading && <PostsTableV3 posts={posts} />}
          </div>
        </div>
      </div>
    </>
  );
}
