/* eslint-disable @typescript-eslint/no-explicit-any */
// Custom Tooltip Component
import React from "react";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  formatFunction: (value: number) => string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  formatFunction,
}) => {
  if (active && payload && payload.length) {
    const { value } = payload[0];
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          border: "1px solid #cccccc",
          padding: "5px 10px",
          borderRadius: "4px",
          boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
        }}
      >
        <p style={{ margin: 0, fontSize: "14px", color: "#333333" }}>
          {formatFunction(value)}
        </p>
      </div>
    );
  }

  return null;
};
